import React, { useMemo, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import { ReactQueryKeys } from 'constants/react-query-keys';
import BalanceService from 'services/BalanceService';
import TableActions from 'components/UI/TableActions';
import { Link, Outlet } from 'react-router-dom';
import { EQUIPMENT_LINKS } from 'constants/equipment-links';
import { formatDateLong } from 'helpers/date-helpers';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { IoIosAdd } from 'react-icons/io';
import { Balance } from 'interfaces/equipment/balance';

const Balances = () => {
  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<Balance>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        className: 'text-center',
        cell: ({ row }) => <div data-testid={`name_${row?.original?.id}`}>{`${row.original.name}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'serial_number',
        accessorKey: 'serial_number',
        header: 'Serial Number',
        className: 'text-center',
        cell: ({ row }) => (
          <div data-testid={`serial_number_${row?.original?.id}`}>{`${row.original.serial_number}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'model_number',
        accessorKey: 'model_number',
        header: 'Model Number',
        className: 'text-center',
        cell: ({ row }) => (
          <div data-testid={`model_number_${row?.original?.id}`}>{`${row.original.model_number}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'manufacturer',
        accessorKey: 'manufacturer',
        header: 'Manufacturer',
        className: 'text-center',
        cell: ({ row }) => (
          <div data-testid={`manufacturer_${row?.original?.id}`}>{`${row.original.manufacturer}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'max_capacity_g',
        accessorKey: 'max_capacity_g',
        header: 'Balance Max Capacity (g)',
        className: 'text-center',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{parseFloat(row?.original?.max_capacity_g + '') + ' g'}</div>,
      },
      {
        id: 'resolution_g',
        accessorKey: 'resolution_g',
        header: 'Balance Resolution (g)',
        className: 'text-center',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{parseFloat(row?.original?.resolution_g + '') + ' g'}</div>,
      },
      {
        id: 'has_draft_shield',
        accessorKey: 'has_draft_shield',
        header: 'Has Draft Shield',
        className: 'text-center',
        cell: ({ row }) => <div>{`${row.original.has_draft_shield ? 'Yes' : 'No'}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'calibration_date',
        accessorKey: 'calibration_date',
        header: 'Calibration Date',
        className: 'text-center',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`calibration_date_${row?.original?.id}`}>
              {row?.original?.calibration_date ? formatDateLong(row?.original?.calibration_date) : 'N/A'}
            </div>
          </div>
        ),
      },
      {
        id: 'calibration_vendor',
        accessorKey: 'calibration_vendor',
        header: 'Calibration Vendor',
        className: 'text-center',
        cell: ({ row }) => (
          <div
            data-testid={`calibration_vendor_${row?.original?.id}`}
          >{`${row.original.calibration_vendor ?? 'N/A'}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'calibration_tehnician',
        accessorKey: 'calibration_tehnician',
        header: 'Calibration Technician',
        className: 'text-center',
        cell: ({ row }) => (
          <div
            data-testid={`calibration_tehnician_${row?.original?.id}`}
          >{`${row.original.calibration_tehnician ?? 'N/A'}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'calibration_id',
        accessorKey: 'calibration_id',
        header: 'Calibration ID',
        className: 'text-center',
        cell: ({ row }) => (
          <div data-testid={`calibration_id_${row?.original?.id}`}>{`${row.original.calibration_id ?? 'N/A'}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'note',
        accessorKey: 'note',
        header: 'Note',
        className: 'text-center',
        cell: ({ row }) => <div data-testid={`note_${row?.original?.id}`}>{`${row.original.note ?? 'N/A'}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      /*{
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Updated by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => row?.original?.updated_by_user?.name,
      },*/
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: '',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <TableActions editLink={`/configure-equipment/balances/${row.original.id}`} />
          </div>
        ),
      },
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([{ id: 'name', desc: false }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.EQUIPMENT_BALANCES, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      BalanceService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<Balance> = useReactTable({
    data: dataQuery?.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <div>
        <GroupBox
          title="CONFIG: Balances"
          addBtn={
            <Link to="/configure-equipment/balances/add" className="btn-rounded" data-testid="add-btn">
              <IoIosAdd size={40} />
            </Link>
          }
          summary={[
            {
              title: 'BALANCES',
              value: dataQuery.data?.data.total ?? 0,
              classes: '',
            },
          ]}
        >
          <div className="d-flex flex-row">
            <SideTabsLinks links={EQUIPMENT_LINKS} />
            <div className="flex-grow-1 side-tabs__content">
              <Table classes="react-table" isFetching={dataQuery.isFetching} table={table} />
              <Pagination table={table} />
            </div>
          </div>
        </GroupBox>
      </div>
      <Outlet />
    </>
  );
};

export default Balances;
