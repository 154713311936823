import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';
import { TestSchedule } from 'interfaces/tests-scheduler/test-scheduler';
import { TestSchedulerFormData } from 'pages/Lab/TestScheduler/TestSchedule/TestScheduleForm';

const BASE_URL = '/v1/test-schedule';

export type TestScheduleResponse = PaginationResponse<TestSchedule>;

const TestScheduleService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<TestScheduleResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  create: (data: Partial<TestSchedule>) => {
    return axiosInstance.post<TestSchedule>(BASE_URL, data);
  },

  update: (id: string | number, data: Partial<TestSchedule>) => {
    return axiosInstance.patch<TestSchedule>(`${BASE_URL}/${id}`, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<TestSchedule>(`${BASE_URL}/${id}`);
  },
};

export default TestScheduleService;
