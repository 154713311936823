import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { Balance } from 'interfaces/equipment/balance';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/balances';

export type BalanceResponse = PaginationResponse<Balance>;

const BalanceService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
    has_tac?: boolean;
  }) => {
    return axiosInstance.get<BalanceResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | undefined, data: Partial<Balance>) => {
    console.log(data);
    return axiosInstance.patch<Balance>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<Balance>) => {
    return axiosInstance.post<Balance>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<Balance>(`${BASE_URL}/${id}`);
  },
};

export default BalanceService;
