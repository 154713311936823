import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { formatDateLong } from 'helpers/date-helpers';
import { TestProtocol } from 'interfaces/tests-scheduler/test-protocol';
import { useMemo, useState } from 'react';
import { IoIosAdd, IoMdClose } from 'react-icons/io';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import TestProtocolService from 'services/TestProtocolService';
import CreateTestProtocolForm from './TestProtocolForm';
import { TEST_SCHEDULER_LINKS } from 'constants/test-scheduler-links';
import TableActions from 'components/UI/TableActions';

const TestProtocols = () => {
  const [searchParams] = useSearchParams();
  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<TestProtocol>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <div data-testid={`name_${row?.original?.id}`}>{`${row.original.name}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'parameters',
        accessorKey: 'parameters',
        header: 'Stages',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) =>
          !row?.original?.id ? null : (
            <div data-testid={`parameters_${row?.original?.id}`}>
              {row.original.parameters.map((stage, i) => {
                return (
                  <div key={stage.name + i} className="pb-3">
                    <div>Stage Name: {stage.name}</div>
                    <div className="d-flex gap-2">
                      <span>Steps Type:</span>
                      <span className="ps-2">
                        {stage.steps.map((step, stepInd) => {
                          return <div key={step.type + stepInd}>{step.type}</div>;
                        })}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ),
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Note',
        cell: ({ row }) => (
          <div data-testid={`description_${row?.original?.id}`}>{`${row?.original?.description ?? ''}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Updated by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => row?.original?.updated_by_user?.name,
      },
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: 'Update date',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`updated_at_${row?.original?.id}`}>{formatDateLong(row?.original?.updated_at)}</div>
            <TableActions editLink={`/test-data/test-protocol?add=${row.original.id}`} />
          </div>
        ),
      },
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.TEST_PROTOCOLS, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      TestProtocolService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<TestProtocol> = useReactTable({
    data: dataQuery?.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  const isAddingProtocol = searchParams.get('add');

  return (
    <>
      <div>
        <GroupBox
          title="Test Protocol"
          summary={[]}
          addBtn={
            isAddingProtocol ? (
              <Link to="/test-data/test-protocol" className="btn-rounded my-2">
                <IoMdClose size={20} />
              </Link>
            ) : (
              <Link to="/test-data/test-protocol?add=true" className="btn-rounded my-2" data-testid="add-btn">
                <IoIosAdd size={40} />
              </Link>
            )
          }
        >
          <div className="d-flex flex-row">
            <SideTabsLinks links={TEST_SCHEDULER_LINKS} />
            <div className="flex-grow-1 side-tabs__content">
              {isAddingProtocol ? (
                <CreateTestProtocolForm />
              ) : (
                <>
                  <Table classes="users-table" isFetching={dataQuery.isFetching} table={table} />
                  <Pagination table={table} />
                </>
              )}
            </div>
          </div>
        </GroupBox>
      </div>
      <Outlet />
    </>
  );
};

export default TestProtocols;
