import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import IVSetService from 'services/IVSetService';
import DecimalInput from 'components/UI/DecimalInput';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import AsyncSelect from 'react-select/async';
import { useEffect, useState } from 'react';
import { IVSet } from 'interfaces/equipment/iv-set';
import TACService from 'services/TACService';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';

const BACK_URL = '/configure-equipment/iv-sets';

type FormData = {
  name: string;
  description: string | null;
  manufacture_date: Date | null;
  valve_inner_revision: string | null;
  valve_outer_revision: string | null;
  seal_revision: string | null;
  chamber_revision: string | null;
  revision_id: null | number;
  test_automation_controller_id?: number | null;
  test_automation_controller?: { label: string; value: string } | null;
};

const defaultData: FormData = {
  name: '',
  description: null,
  manufacture_date: null,
  chamber_revision: null,
  valve_outer_revision: null,
  valve_inner_revision: null,
  seal_revision: null,
  revision_id: null,
  test_automation_controller_id: null,
  test_automation_controller: null,
};

const IVSetModalForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultData },
  });

  useEffect(() => {
    dispatch(overlayActions.open({ path: BACK_URL }));

    if (params.id) {
      setFormLoading(true);
      IVSetService.find(params.id)
        .then(({ data }: { data: IVSet }) => {
          setValue('name', data.name);
          setValue('description', data.description);
          setValue('manufacture_date', data.manufacture_date);
          setValue('chamber_revision', data.chamber_revision);
          setValue('valve_outer_revision', data.valve_outer_revision);
          setValue('valve_inner_revision', data.valve_inner_revision);
          setValue('seal_revision', data.seal_revision);
          setValue('revision_id', data.revision_id);

          if (data.test_automation_controller) {
            setValue('test_automation_controller', {
              label: String(data.test_automation_controller.name),
              value: String(data.test_automation_controller.id),
            });
          }

          setFormLoading(false);
        })
        .catch(() => {
          toast.error('The infusion device could not be loaded.');
          navigate(BACK_URL);
        });
    }
  }, []);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      name: formData.name.trim(),
      description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
      manufacture_date: formData.manufacture_date === null ? null : formData.manufacture_date,
      chamber_revision:
        formData.chamber_revision === '' || formData.chamber_revision === null
          ? null
          : formData.chamber_revision?.trim(),
      valve_outer_revision:
        formData.valve_outer_revision === '' || formData.valve_outer_revision === null
          ? null
          : formData.valve_outer_revision?.trim(),
      valve_inner_revision:
        formData.valve_inner_revision === '' || formData.valve_inner_revision === null
          ? null
          : formData.valve_inner_revision?.trim(),
      seal_revision:
        formData.seal_revision === '' || formData.seal_revision === null ? null : formData.seal_revision?.trim(),
      revision_id: formData.revision_id === null ? null : Number(formData.revision_id),
      test_automation_controller_id: formData.test_automation_controller?.value
        ? +formData.test_automation_controller?.value
        : null,
    };

    if (params.id) {
      try {
        await IVSetService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_IVSET_TYPES],
        });
        toast.success('The IV Set was successfully updated!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The IV Set could not be updated. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await IVSetService.create(data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_IVSET_TYPES],
        });
        toast.success('The IV Set was successfully created!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The IV Set could not be created. Please try again.';
        if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  /**
   * Filter list of TACs that are not assigned
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of drugs
   */
  const tacOptions = (inputValue: string) =>
    TACService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
      has_iv_set: false,
    }).then((response) => {
      return response.data.records.map((record: TestAutomationController) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (formLoading || isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading || formLoading ? <Spinner isAbsolute /> : null}

        <div className="row">
          {/* IV Set Name */}
          <div className="col-12">
            <label htmlFor="name" className="form-label">
              IV Set Name *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="name"
              data-testid="name"
              {...register('name', {
                required: true,
                maxLength: 32,
                minLength: 1,
              })}
            />
            {errors?.name?.type === 'required' && <div className="invalid-feedback pt-1">IV Set Name is required</div>}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">IV Set Name must have maximum 32 characters</div>
            )}
            {errors?.name?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">IV Set Name must have minimum 1 character</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Manufacture Date */}
          <div className="col-6">
            <label htmlFor="manufacture_date" className="form-label">
              Manufacture Date
            </label>
            <Controller
              control={control}
              name="manufacture_date"
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  wrapperClassName="form-control"
                  className="form-control form-control-lg rounded"
                  id="manufacture_date"
                  selected={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isClearable={true}
                  maxDate={new Date()}
                  ref={ref}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              )}
            />
          </div>
          {/* Chamber Revision */}
          <div className="col-6">
            <label htmlFor="description" className="form-label">
              Chamber Revision
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="chamber_revision"
              data-testid="chamber_revision"
              {...register('chamber_revision', {
                required: false,
                maxLength: 16,
              })}
            />
            {errors?.chamber_revision?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Chamber Revision must have maximum 16 characters</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Valve Outer Revision */}
          <div className="col-6">
            <label htmlFor="description" className="form-label">
              Valve Outer Revision
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="valve_outer_revision"
              data-testid="valve_outer_revision"
              {...register('valve_outer_revision', {
                required: false,
                maxLength: 16,
              })}
            />
            {errors?.valve_outer_revision?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Valve Outer Revision must have maximum 16 characters</div>
            )}
          </div>

          {/* Valve Inner Revision */}
          <div className="col-6">
            <label htmlFor="description" className="form-label">
              Valve Inner Revision
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="valve_inner_revision"
              data-testid="valve_inner_revision"
              {...register('valve_inner_revision', {
                required: false,
                maxLength: 16,
              })}
            />
            {errors?.valve_inner_revision?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Valve Inner Revision must have maximum 16 characters</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/*Seal Revision */}
          <div className="col-6">
            <label htmlFor="description" className="form-label">
              Seal Revision
            </label>
            <input
              type="text"
              className="form-control form-control-lg rounded"
              id="seal_revision"
              data-testid="seal_revision"
              {...register('seal_revision', {
                required: false,
                maxLength: 16,
              })}
            />
            {errors?.seal_revision?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Seal Revision must have maximum 16 characters</div>
            )}
          </div>

          {/* Revision ID */}
          <div className="col-6">
            <label htmlFor="revision_id" className="form-label">
              Revision ID
            </label>
            <Controller
              control={control}
              name="revision_id"
              rules={{
                required: false,
                min: 1,
                max: 999,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  className={'form-control form-control-lg rounded'}
                  id="revision_id"
                  data-testid="revision_id"
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={0}
                  ref={ref}
                  alignCenter={false}
                />
              )}
            />
            {(errors?.revision_id?.type === 'max' || errors?.revision_id?.type === 'min') && (
              <div className="invalid-feedback pt-1">Revision ID value should be between 1 and 999</div>
            )}
          </div>
        </div>
        {/* Paired TAC */}
        <div className="row pt-4">
          <div className="col-12">
            <label htmlFor="test_automation_controller" className="form-label">
              Paired TAC
            </label>
            <Controller
              control={control}
              name="test_automation_controller"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="test_automation_controller"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={tacOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Note */}
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={isSubmitting || isLoading || formLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${isSubmitting || isLoading || formLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default IVSetModalForm;
