export const EQUIPMENT_LINKS = [
  {
    to: '/configure-equipment/needle-types',
    title: 'NEEDLE TYPES',
  },
  {
    to: '/configure-equipment/fluid-types',
    title: 'FLUID TYPES',
  },
  {
    to: '/configure-equipment/fluid-sources',
    title: 'FLUID SOURCES',
  },
  {
    to: '/configure-equipment/container-types',
    title: 'CONTAINER TYPES',
  },
  {
    to: '/configure-equipment/iv-sets',
    title: 'IV SETS',
  },
  {
    to: '/configure-equipment/infusion-devices',
    title: 'INFUSION DEVICES',
  },
  {
    to: '/configure-equipment/balances',
    title: 'BALANCES',
  },
  {
    to: '/configure-equipment/test-automation-controllers',
    title: 'TEST AUTOMATION CONTROLLERS',
  },
  {
    to: '/configure-equipment/tac-groups',
    title: 'TAC GROUPS',
  },
];
