import { createBrowserRouter, Navigate } from 'react-router-dom';
import AdminLayout from './layout/AdminLayout';
import Dashboard from './pages/Dashboard';
import NotFound from 'pages/NotFound';
import ProtectedRoute from 'components/guards/ProtectedRoute';
import Users from 'pages/Settings/Users/Users';
import { ROLE_ADMIN, ROLE_LAB_TECHNICIAN } from 'constants/roles';
import Settings from 'pages/Settings/Settings';
import UsersModalForm from 'pages/Settings/Users/UsersModalForm';
import ModalSidebar from 'components/UI/ModalSidebar';
import LabMonitor from 'pages/Lab/LabMonitor/LabMonitor';
import TestScheduler from 'pages/Lab/TestScheduler/TestSchedule/TestSchedules';
import ConfigureEquipment from 'pages/Lab/Equipment/ConfigureEquipment';
import NeedleTypes from 'pages/Lab/Equipment/Needles/NeedleTypes';
import NeedleTypesModalForm from 'pages/Lab/Equipment/Needles/NeedleTypesModalForm';
import FluidTypes from 'pages/Lab/Equipment/FluidTypes/FluidTypes';
import FluidTypesModalForm from 'pages/Lab/Equipment/FluidTypes/FluidTypesModalForm';
import FluidSources from 'pages/Lab/Equipment/FluidSources/FluidSources';
import FluidSourcesModalForm from 'pages/Lab/Equipment/FluidSources/FluidSourcesModalForm';
import ContainerTypes from 'pages/Lab/Equipment/Containers/ContainerTypes';
import ContainerTypesModalForm from 'pages/Lab/Equipment/Containers/ContainerTypesModalForm';
import IVSets from 'pages/Lab/Equipment/IVSets/IVSets';
import IVSetModalForm from 'pages/Lab/Equipment/IVSets/IVSetsModalForm';
import InfusionDevices from 'pages/Lab/Equipment/InfusionDevices/InfusionDevices';
import InfusionDevicesModalForm from 'pages/Lab/Equipment/InfusionDevices/InfusionDevicesModalForm';
import TestAutomationControllers from 'pages/Lab/Equipment/TestAutomationControllers/TestAutomationControllers';
import TestAutomationControllersModalForm from 'pages/Lab/Equipment/TestAutomationControllers/TestAutomationControllersModalForm';
import Balances from 'pages/Lab/Equipment/Balances/Balances';
import BalancesModalForm from 'pages/Lab/Equipment/Balances/BalancesModalForm';
import TestGroups from 'pages/Lab/Equipment/TestGroups/TestGroups';
import TestGroupsModalForm from 'pages/Lab/Equipment/TestGroups/TestGroupsModal';
import AccessTokens from 'pages/Settings/ApiKeys/AccessTokens';
import AccessTokensModalForm from 'pages/Settings/ApiKeys/AccessTokensModalForm';
import TestProtocols from 'pages/Lab/TestScheduler/TestProtocols/TestProtocol';
import TestData from 'pages/Lab/TestScheduler/TestData';
import TestSchedule from 'pages/Lab/TestScheduler/TestSchedule/TestSchedules';

export const router = createBrowserRouter([
  {
    id: 'root',
    path: '/',
    element: <AdminLayout />,
    children: [
      {
        path: '',
        element: <Dashboard />,
      },
      {
        path: 'lab-monitor',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <LabMonitor />
          </ProtectedRoute>
        ),
      },
      {
        path: 'test-data',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <TestData />
          </ProtectedRoute>
        ),
        children: [
          {
            // Redirect to 'test-schedule' by default
            index: true,
            element: <Navigate to="test-schedule" />,
          },
          {
            path: 'test-schedule',
            element: <TestSchedule />,
          },
          {
            path: 'test-protocol',
            element: <TestProtocols />,
          },
        ],
      },
      {
        path: `configure-equipment`,
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN, ROLE_LAB_TECHNICIAN]}>
            <ConfigureEquipment />
          </ProtectedRoute>
        ),
        children: [
          {
            // Redirect to 'needle-types' by default
            index: true,
            element: <Navigate to="needle-types" />,
          },
          {
            path: 'needle-types',
            element: <NeedleTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Needle Type">
                    <NeedleTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Needle Type">
                    <NeedleTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'fluid-types',
            element: <FluidTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Fluid Type">
                    <FluidTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Fluid Type">
                    <FluidTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'fluid-sources',
            element: <FluidSources />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Fluid Source">
                    <FluidSourcesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Fluid Source">
                    <FluidSourcesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'container-types',
            element: <ContainerTypes />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Container Type">
                    <ContainerTypesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Container Type">
                    <ContainerTypesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'iv-sets',
            element: <IVSets />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit IV Set Type">
                    <IVSetModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add IV Set Type">
                    <IVSetModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'test-automation-controllers',
            element: <TestAutomationControllers />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Test Automation Controller">
                    <TestAutomationControllersModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'tac-groups',
            element: <TestGroups />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit TAC Group">
                    <TestGroupsModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add TAC Group">
                    <TestGroupsModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'infusion-devices',
            element: <InfusionDevices />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Infusion Device">
                    <InfusionDevicesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Infusion Device">
                    <InfusionDevicesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'balances',
            element: <Balances />,
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit Balance">
                    <BalancesModalForm />
                  </ModalSidebar>
                ),
              },
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Balance">
                    <BalancesModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'settings',
        element: (
          <ProtectedRoute roles={[ROLE_ADMIN]}>
            <Settings />
          </ProtectedRoute>
        ),
        children: [
          {
            path: 'users',
            element: (
              <ProtectedRoute roles={[ROLE_ADMIN]}>
                <Users />
              </ProtectedRoute>
            ),
            children: [
              {
                path: ':id',
                element: (
                  <ModalSidebar title="Edit User">
                    <UsersModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
          {
            path: 'access-tokens',
            element: (
              <ProtectedRoute roles={[ROLE_ADMIN]}>
                <AccessTokens />
              </ProtectedRoute>
            ),
            children: [
              {
                path: 'add',
                element: (
                  <ModalSidebar title="Add Access Token">
                    <AccessTokensModalForm />
                  </ModalSidebar>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);
