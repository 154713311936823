export const ReactQueryKeys = {
  USERS: 'users',
  EQUIPMENT_FLUID_TYPES: 'fluid_types',
  EQUIPMENT_NEEDLE_TYPES: 'needle_types',
  EQUIPMENT_CONTAINER_TYPES: 'container_types',
  EQUIPMENT_IVSET_TYPES: 'ivset_types',
  EQUIPMENT_FLUID_SOURCES: 'fluid_sources',
  EQUIPMENT_TAC: 'test_automation_controllers',
  EQUIPMENT_TEST_GROUPS: 'test_groups',
  EQUIPMENT_INFUSION_DEVICES: 'infusion_devices',
  EQUIPMENT_BALANCES: 'balances',
  SETTINGS_ACCESS_TOKENS: 'access_tokens',
  TEST_PROTOCOLS: 'test_protocols',
  TEST_SCHEDULER: 'test_scheduler',
};
