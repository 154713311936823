import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { TestGroup } from 'interfaces/equipment/test-group';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/tac-groups';

export type TestGrouopResponse = PaginationResponse<TestGroup>;

const TestGroupService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<TestGrouopResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (
    id: string | number,
    data: Omit<Partial<TestGroup>, 'test_automation_controllers'> & { test_automation_controllers: number[] }
  ) => {
    return axiosInstance.patch<TestGroup>(`${BASE_URL}/${id}`, data);
  },

  create: (
    data: Omit<Partial<TestGroup>, 'test_automation_controllers'> & { test_automation_controllers: number[] }
  ) => {
    return axiosInstance.post<TestGroup>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<TestGroup>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<TestGroup>(`${BASE_URL}/${id}`);
  },

  removeTacFromGroup: (testGroupId: number, tacId: number) => {
    return axiosInstance.delete<TestGroup>(`${BASE_URL}/group-tac/${testGroupId}/${tacId}`);
  },
};

export default TestGroupService;
