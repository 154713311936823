type ServerErrors = {
  property: string;
  children: ServerErrors[];
  constraints: { [key: string]: string };
};

type ServerErrorData = {
  statusCode: number;
  message: string;
  errors?: ServerErrors[];
};

/**
 * Parse validation error message
 *
 * @param {ServerErrorData} data API Error data
 * @returns string
 */
export const parseErrorMessage = (data: ServerErrorData): string => {
  const findConstraintMessage = (message: any): string | null => {
    if (typeof message.constraints === 'object' && !!Object.keys(message.constraints).length) {
      // Return the first constraint message found
      //@ts-ignore
      return Object.values(message.constraints)[0];
    }

    if (Array.isArray(message.children) && message.children.length > 0) {
      // Recursively check the children
      for (const child of message.children) {
        const result = findConstraintMessage(child);
        if (result) {
          return result;
        }
      }
    }

    return null;
  };

  if (Array.isArray(data.message) && data.message.length > 0) {
    const message = findConstraintMessage(data.message[0]);
    if (message) {
      return message;
    }
  }

  return String(data.message);
};
