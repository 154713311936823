import { InputHTMLAttributes, Ref, forwardRef } from 'react';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  classname?: string;
}

// eslint-disable-next-line react/display-name
const Checkbox = forwardRef(
  (
    { id, title, value, onChange, checked, disabled, onBlur, classname, name, ...rest }: CheckboxProps,
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <label htmlFor={id} className={'label-cbx ' + (classname ? classname : '')}>
        <input
          id={id}
          type="checkbox"
          className="label-cbx-invisible"
          value={value}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          ref={ref}
          name={name}
          {...rest}
        />
        <div className="checkbox">
          <svg width="20px" height="20px" viewBox="0 0 20 20">
            <path d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"></path>
            <polyline points="4 11 8 15 16 6"></polyline>
          </svg>
        </div>
        {title && <span>{title}</span>}
      </label>
    );
  }
);

export default Checkbox;
