import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/tac';

export type TACResponse = PaginationResponse<TestAutomationController>;

const TACService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
    has_infusion_device?: boolean;
    has_iv_set?: boolean;
  }) => {
    return axiosInstance.get<TACResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<TestAutomationController>) => {
    return axiosInstance.patch<TestAutomationController>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<TestAutomationController>) => {
    return axiosInstance.post<TestAutomationController>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<TestAutomationController>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<TestAutomationController>(`${BASE_URL}/${id}`);
  },
};

export default TACService;
