import ContentHeader from 'components/UI/ContentHeader';
import ReactTooltip from 'components/UI/ReactTooltip';
import { IoIosAdd } from 'react-icons/io';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const SETTINGS_LINKS = [
  {
    title: 'Users',
    url: '/settings/users',
  },
  {
    title: 'Access Tokens',
    url: '/settings/access-tokens',
    actions: (
      <>
        <span data-tooltip-id="content-header-button" data-tooltip-content={'Add Api Key'}>
          <Link to="/settings/access-tokens/add" className="btn-rounded" data-testid="add-company">
            <IoIosAdd size={40} />
          </Link>
        </span>
        <ReactTooltip id="content-header-button" place="bottom" style={{ fontSize: '13px' }} />
      </>
    ),
  },
];

const Settings = () => {
  const location = useLocation();
  const actions = SETTINGS_LINKS.find((link) => link.url === location.pathname)?.actions;

  return (
    <>
      <ContentHeader links={SETTINGS_LINKS} title="Settings" actions={actions} />
      <div className="main-container with-tabs">
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
