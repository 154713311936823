import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import DecimalInput from 'components/UI/DecimalInput';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { parseErrorMessage } from 'helpers/parse-error-message';
import {
  Control,
  Controller,
  FieldErrors,
  useFieldArray,
  useForm,
  UseFormClearErrors,
  UseFormRegister,
} from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoMdTrash } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import TestProtocolService from 'services/TestProtocolService';
import { Tooltip } from 'react-tooltip';

type ProgramDeliveryStepForm = {
  type: 'Program Delivery';
  delivery_rate: number | null;
  delivery_vtbi: number | null;
};

type StopDeliveryStepForm = {
  type: 'Stop Delivery';
  stop_delivery: true;
};

type DiverterCmdStepForm = {
  type: 'Diverter Command';
  diverter_cmd: 'DIVERT' | 'DELIVER' | null;
};

export type WaitForEventStepForm = {
  type: 'Wait for Event';
} & (WaitForTime | WaitForDeliveryState | WaitForVolume | WaitForAlarm | { event_type: null });
type WaitForTime = { event_type: 'wait_for_time'; seconds_to_wait: number | null };
type WaitForDeliveryState = {
  event_type: 'wait_for_delivery_state';
  device_is_delivering: 'Occluding' | 'Delivering' | 'Not Delivering' | null;
};
type WaitForAlarm = { event_type: 'wait_for_alarm_state'; device_has_error_or_alarm: boolean };
type WaitForVolume = { event_type: 'wait_for_volume'; wait_for_ml: number | null };

export type StepsForm =
  | ProgramDeliveryStepForm
  | StopDeliveryStepForm
  | DiverterCmdStepForm
  | WaitForEventStepForm
  | { type: null };

export type StagesForm = {
  name: string;
  steps: StepsForm[];
  analysis_enabled: boolean;
};

export type TestProtocolFormData = { name: string; description: null | string; stages: StagesForm[] };

export const DEFAULT_PROGRAM_DELIVERY_STEP: ProgramDeliveryStepForm = {
  type: 'Program Delivery',
  delivery_rate: null,
  delivery_vtbi: null,
};
export const DEFAULT_STOP_DELIVERY_STEP: StopDeliveryStepForm = { type: 'Stop Delivery', stop_delivery: true };
export const DEFAULT_DIVERT_STEP: DiverterCmdStepForm = { type: 'Diverter Command', diverter_cmd: null };
export const DEFAULT_WAIT_FOR_EVENT_STEP: WaitForEventStepForm = { type: 'Wait for Event', event_type: null };

const defaultData: TestProtocolFormData = {
  name: '',
  description: null,
  stages: [],
};

const BACK_URL = '/test-data/test-protocol';

const CreateTestProtocolForm = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const testProtocolIdToBeUpdated = Number(searchParams.get('add'));

  //if creating a protocol, 'add' value is a boolean (that turns into Nan when forced into a number), if updating a number
  const isCreating = isNaN(testProtocolIdToBeUpdated);
  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<TestProtocolFormData>({
    mode: 'all',
    defaultValues: async () => {
      if (isCreating) {
        return defaultData;
      } else {
        try {
          const { data } = await TestProtocolService.find(testProtocolIdToBeUpdated);
          return { ...data, stages: data.parameters };
        } catch (error) {
          toast.error('The Test Protocol could not be loaded.');
          navigate(BACK_URL);
          return defaultData;
        }
      }
    },
  });

  const { fields: stagesFields, append, remove } = useFieldArray<TestProtocolFormData>({ name: 'stages', control });

  /**
   * Submit form and create TAC
   *
   * @param formData TAC data
   */
  const handleSubmitData = async (formData: TestProtocolFormData) => {
    const data = {
      ...formData,
      name: formData.name.trim(),
      description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
    };

    if (isCreating) {
      try {
        await TestProtocolService.create(data);

        toast.success('The Test Protocol was successfully created!');
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.TEST_PROTOCOLS],
        });
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The Test Protocol could not be created. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await TestProtocolService.update(testProtocolIdToBeUpdated, data);

        toast.success('The Test Protocol was successfully updated!');
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.TEST_PROTOCOLS],
        });
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The Test Protocol could not be updated. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
  };

  return (
    <>
      <div className="card bg-primary">
        <div className="card-header bg-primary">
          <h5 className="card-title mb-0">{isCreating ? 'Create' : 'Update'} Test Protocol</h5>
        </div>
        <div className="card-body bg-dark">
          <form onSubmit={handleSubmit((data) => handleSubmitData(data))}>
            <div className="row py-2">
              {/* Test Protocol Name */}
              <div className="col-6">
                <label htmlFor="name" className="form-label">
                  Test Protocol Name *
                </label>
                <input
                  type="text"
                  autoFocus
                  className="form-control form-control-lg rounded"
                  id="name"
                  data-testid="name"
                  {...register('name', {
                    required: true,
                    maxLength: 32,
                    minLength: 3,
                  })}
                />
                {errors?.name?.type === 'required' && (
                  <div className="invalid-feedback pt-1">Test Protocol Name is required</div>
                )}
                {errors?.name?.type === 'maxLength' && (
                  <div className="invalid-feedback pt-1">Test Protocol Name must have maximum 32 characters</div>
                )}
                {errors?.name?.type === 'minLength' && (
                  <div className="invalid-feedback pt-1">Test Protocol Name must have minimum 3 characters</div>
                )}
              </div>
            </div>
            <div className="row pt-2 pb-4">
              {/* Note */}
              <div className="col-12">
                <label htmlFor="description" className="form-label">
                  Note
                </label>
                <textarea
                  rows={1}
                  id="description"
                  className="form-control form-control-lg rounded"
                  data-testid="description"
                  {...register('description', {
                    required: false,
                    maxLength: 128,
                  })}
                ></textarea>
                {errors?.description?.type === 'maxLength' && (
                  <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
                )}
              </div>
            </div>

            <div className="card bg-primary mb-4">
              <div className="card-header bg-primary">
                <h5 className="card-title mb-0">Stages</h5>
              </div>
              <div className="bg-dark card-body">
                {stagesFields.map((stage, index) => {
                  return (
                    <div key={stage.id} className={`my-4 ${index ? 'top-line' : ''}`}>
                      <div className="d-flex mt-3">
                        <h5 className="flex-grow-1 " style={{ fontSize: '18px' }}>
                          {' '}
                          Stage index: {index + 1}
                        </h5>
                        <button
                          className="delete-icon"
                          data-tooltip-id={`delete-stage-${index}`}
                          data-tooltip-content={`Remove stage index: ${index + 1}`}
                        >
                          <IoMdTrash
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        </button>
                        <Tooltip id={`delete-stage-${index}`} />
                      </div>

                      <div className="">
                        <div>
                          <label htmlFor={`stages.${index}.name`} className="form-label">
                            Stage Name *
                          </label>
                          <input
                            type="text"
                            autoFocus
                            className="form-control form-control-lg rounded"
                            id={`stages.${index}.name`}
                            data-testid={`stages.${index}.name`}
                            {...register(`stages.${index}.name`, {
                              required: true,
                              maxLength: 32,
                              minLength: 3,
                            })}
                          />
                          {errors.stages?.[index]?.name?.type === 'required' && (
                            <div className="invalid-feedback">Stage Name is required</div>
                          )}
                          {errors.stages?.[index]?.name?.type === 'maxLength' && (
                            <div className="invalid-feedback pt-1">Stage Name must have maximum 32 characters</div>
                          )}
                          {errors.stages?.[index]?.name?.type === 'minLength' && (
                            <div className="invalid-feedback pt-1">Stage Name must have minimum 3 characters</div>
                          )}
                        </div>
                        <StepsFieldArray
                          control={control}
                          clearErrors={clearErrors}
                          register={register}
                          errors={errors}
                          stageIndex={index}
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="d-flex justify-content-end mt-4">
                  <button
                    type="button"
                    className="btn btn-primary me-2"
                    onClick={() => append({ steps: [], name: '', analysis_enabled: false })}
                  >
                    Add stage
                  </button>
                </div>
              </div>
            </div>
            <Button
              loading={isSubmitting}
              defaultLabel={isCreating ? 'Save' : 'Update'}
              loadingLabel={isCreating ? 'Saving' : 'Updating'}
              type="submit"
              disabled={isLoading || isSubmitting}
            />
          </form>
        </div>
      </div>
    </>
  );
};

const STEPS_TYPE_ARRAY = ['Program Delivery', 'Stop Delivery', 'Diverter Command', 'Wait for Event'] as const;

const StepsFieldArray = ({
  control,
  errors,
  stageIndex,
  clearErrors,
}: {
  stageIndex: number;
  control: Control<TestProtocolFormData>;
  register: UseFormRegister<TestProtocolFormData>;
  errors: FieldErrors<TestProtocolFormData>;
  clearErrors: UseFormClearErrors<TestProtocolFormData>;
}) => {
  const {
    fields: stepsFields,
    append: appendStep,
    remove: removeStep,
    update: updateStep,
  } = useFieldArray({
    name: `stages.${stageIndex}.steps`,
    control,
  });

  return (
    <>
      <div className="">
        {stepsFields.map((step, stepIndex) => {
          const stepErrorObject = errors.stages?.[stageIndex]?.steps?.[stepIndex];
          return (
            <div key={step.id} className="mt-5 ps-5">
              <div className="d-flex">
                <h5 className="flex-grow-1 "> Step {stepIndex + 1}</h5>
                <button
                  onClick={() => {
                    removeStep(stepIndex);
                  }}
                  className="btn btn-secondary mt-2"
                >
                  Remove step
                </button>{' '}
              </div>

              <Controller
                control={control}
                name={`stages.${stageIndex}.steps.${stepIndex}.type`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={(option) => {
                      clearErrors(`stages.${stageIndex}.steps.${stepIndex}.type`);

                      onChange(option);

                      switch (option?.label) {
                        case 'Diverter Command':
                          return updateStep(stepIndex, DEFAULT_DIVERT_STEP);
                        case 'Program Delivery':
                          return updateStep(stepIndex, DEFAULT_PROGRAM_DELIVERY_STEP);
                        case 'Stop Delivery':
                          return updateStep(stepIndex, DEFAULT_STOP_DELIVERY_STEP);
                        case 'Wait for Event':
                          return updateStep(stepIndex, DEFAULT_WAIT_FOR_EVENT_STEP);
                        default:
                          return updateStep(stepIndex, { ...step, type: null });
                      }
                    }}
                    isClearable
                    isMulti={false}
                    options={STEPS_TYPE_ARRAY.map((type) => ({ value: type, label: type }))}
                    className="react-select-container my-2"
                    classNamePrefix="react-select"
                    maxMenuHeight={300}
                    onBlur={onBlur}
                    ref={ref}
                    value={value ? { value, label: value } : null}
                  />
                )}
              />
              {
                //@ts-ignore
                stepErrorObject?.type?.type === 'required' && (
                  <div className="invalid-feedback pt-1">Step Type is required</div>
                )
              }

              {step.type === 'Program Delivery' && (
                <>
                  <div className="row pt-4">
                    {/* Delivery Rate */}
                    <div className="col-6">
                      <label htmlFor={`stages.${stageIndex}.steps.${stepIndex}.delivery_rate`} className="form-label">
                        Delivery Rate *
                      </label>
                      <Controller
                        control={control}
                        name={`stages.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                        rules={{
                          required: true,
                          min: 0,
                          max: 999999.99,
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DecimalInput
                            className={'form-control form-control-lg rounded'}
                            id={`stages.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                            data-testid={`stages.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            precision={2}
                            ref={ref}
                            alignCenter={false}
                            inputGroup={true}
                            inputGroupText="mL/hr"
                          />
                        )}
                      />
                      {
                        //@ts-ignore
                        stepErrorObject?.delivery_rate?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Delivery Rate is required</div>
                        )
                      }
                      {
                        //@ts-ignore
                        (stepErrorObject?.delivery_rate?.type === 'min' ||
                          //@ts-ignore
                          stepErrorObject?.delivery_rate?.type === 'max') && (
                          <div className="invalid-feedback pt-1">
                            Delivery Rate value should be between 0 and 999999.99
                          </div>
                        )
                      }
                    </div>
                    {/* Delivery VTBI */}
                    <div className="col-6">
                      <label htmlFor={`stages.${stageIndex}.steps.${stepIndex}.delivery_vtbi`} className="form-label">
                        Delivery VTBI *
                      </label>
                      <Controller
                        control={control}
                        name={`stages.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                        rules={{
                          required: true,
                          min: 0,
                          max: 999999.99,
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DecimalInput
                            className={'form-control form-control-lg rounded'}
                            id={`stages.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                            data-testid={`stages.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            precision={2}
                            ref={ref}
                            alignCenter={false}
                            inputGroup={true}
                            inputGroupText="mL"
                          />
                        )}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.delivery_vtbi?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Delivery VTBI is required</div>
                        )
                      }
                      {
                        //@ts-ignore
                        (stepErrorObject?.delivery_vtbi?.type === 'min' ||
                          //@ts-ignore
                          stepErrorObject?.delivery_vtbi?.type === 'max') && (
                          <div className="invalid-feedback pt-1">
                            Delivery VTBI value should be between 0 and 999999.99
                          </div>
                        )
                      }
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Stop Delivery' && (
                <>
                  <div className="row pt-4">
                    {/*  Stop Delivery */}
                    <div className="col-12">
                      <label
                        htmlFor={`stages.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                        className="form-label pe-5"
                      >
                        Stop Delivery
                      </label>
                      <Controller
                        control={control}
                        name={`stages.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                        rules={{}}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Checkbox
                            disabled={true}
                            id={`stages.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                            data-testid={`stages.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                            title=""
                            checked={value}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={`stages.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Diverter Command' && (
                <>
                  <div className="row pt-4">
                    {/*  Diverter Command */}
                    <div className="col-6">
                      <label
                        htmlFor={`stages.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                        className="form-label pe-2"
                      >
                        Command *
                      </label>
                      <Controller
                        control={control}
                        name={`stages.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Select
                            id={`stages.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                            onChange={(option) => {
                              clearErrors(`stages.${stageIndex}.steps.${stepIndex}.diverter_cmd`);
                              onChange(option);
                              updateStep(stepIndex, {
                                ...step,
                                diverter_cmd: option?.value ?? null,
                              });
                            }}
                            isMulti={false}
                            isClearable
                            options={(['DIVERT', 'DELIVER'] as const).map((cmd) => ({
                              value: cmd,
                              label: cmd.charAt(0) + cmd.slice(1).toLowerCase(),
                            }))}
                            className="react-select-container my-2"
                            classNamePrefix="react-select"
                            maxMenuHeight={300}
                            onBlur={onBlur}
                            ref={ref}
                            value={value ? { value, label: value.charAt(0) + value.slice(1).toLowerCase() } : null}
                          />
                        )}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.diverter_cmd?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Command is required</div>
                        )
                      }
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Wait for Event' && (
                <>
                  <div className="row pt-4 my-2">
                    {/*  Wait for Event */}
                    <div className="col-6">
                      <label htmlFor={`stages.${stageIndex}.steps.${stepIndex}.event_type`} className="form-label pe-2">
                        Event Type *
                      </label>
                      <Controller
                        control={control}
                        name={`stages.${stageIndex}.steps.${stepIndex}.event_type`}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref } }) => {
                          const WAIT_EVENT_LABELS: Record<Exclude<WaitForEventStepForm['event_type'], null>, string> = {
                            wait_for_alarm_state: 'Error/Alarms',
                            wait_for_time: 'Time Elapsed',
                            wait_for_volume: 'Volume Delivered',
                            wait_for_delivery_state: 'Device Status',
                          };
                          return (
                            <Select
                              onChange={(option) => {
                                clearErrors(`stages.${stageIndex}.steps.${stepIndex}.event_type`);
                                onChange(option?.value);

                                switch (option?.value) {
                                  case 'wait_for_alarm_state':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      device_has_error_or_alarm: false,
                                    });
                                  case 'wait_for_delivery_state':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      device_is_delivering: null,
                                    });
                                  case 'wait_for_time':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      seconds_to_wait: null,
                                    });
                                  case 'wait_for_volume':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      wait_for_ml: null,
                                    });

                                  default:
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: null,
                                    });
                                }
                              }}
                              options={(
                                [
                                  'wait_for_time',
                                  'wait_for_volume',
                                  'wait_for_delivery_state',
                                  'wait_for_alarm_state',
                                ] as const
                              ).map((value) => ({ value, label: WAIT_EVENT_LABELS[value] }))}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              maxMenuHeight={300}
                              onBlur={onBlur}
                              ref={ref}
                              value={value ? { value, label: WAIT_EVENT_LABELS[value] } : null}
                              isClearable
                            />
                          );
                        }}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.event_type?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Event Type is required</div>
                        )
                      }
                    </div>
                    <div className="col-6 mt-auto">
                      {step.event_type === 'wait_for_volume' && (
                        <>
                          <label htmlFor={`stages.${stageIndex}.steps.${stepIndex}.wait_for_ml`} className="form-label">
                            Wait for (x) mL *
                          </label>
                          <Controller
                            control={control}
                            name={`stages.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                            rules={{
                              required: true,
                              min: 0,
                              max: 999999.99,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DecimalInput
                                className={'form-control form-control-lg rounded'}
                                id={`stages.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                                data-testid={`stages.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                                value={String(value)}
                                onChange={onChange}
                                onBlur={onBlur}
                                precision={2}
                                ref={ref}
                                alignCenter={false}
                                inputGroup={true}
                                inputGroupText="mL"
                              />
                            )}
                          />
                          {
                            //@ts-ignore
                            stepErrorObject?.wait_for_ml?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Volume Delivered is required</div>
                            )
                          }
                          {
                            //@ts-ignore
                            (stepErrorObject?.wait_for_ml?.type === 'min' ||
                              //@ts-ignore
                              stepErrorObject?.wait_for_ml?.type === 'max') && (
                              <div className="invalid-feedback pt-1">
                                Volume Delivered value should be between 0 and 999999.99
                              </div>
                            )
                          }
                        </>
                      )}

                      {step.event_type === 'wait_for_time' && (
                        <>
                          <label
                            htmlFor={`stages.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                            className="form-label"
                          >
                            Wait Duration *
                          </label>
                          <Controller
                            control={control}
                            name={`stages.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                            rules={{
                              required: true,
                              min: 1,
                              max: 99999,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DecimalInput
                                className={'form-control form-control-lg rounded'}
                                id={`stages.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                                data-testid={`stages.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                                value={String(value)}
                                onChange={onChange}
                                onBlur={onBlur}
                                precision={0}
                                ref={ref}
                                alignCenter={false}
                                inputGroup={true}
                                inputGroupText="seconds"
                              />
                            )}
                          />
                          {
                            //@ts-ignore
                            stepErrorObject?.seconds_to_wait?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Wait Duration is required</div>
                            )
                          }
                          {
                            //@ts-ignore
                            (stepErrorObject?.seconds_to_wait?.type === 'min' ||
                              //@ts-ignore
                              stepErrorObject?.seconds_to_wait?.type === 'max') && (
                              <div className="invalid-feedback pt-1">
                                Wait Duration value should be between 1 and 99999
                              </div>
                            )
                          }
                        </>
                      )}

                      {step.event_type === 'wait_for_alarm_state' && (
                        <>
                          <label
                            htmlFor={`stages.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                            className="form-label pe-5"
                          >
                            Device has error or alarm
                          </label>
                          <Controller
                            control={control}
                            name={`stages.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                            rules={{}}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <Checkbox
                                id={`stages.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                                checked={value}
                                ref={ref}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={`stages.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                              />
                            )}
                          />
                        </>
                      )}

                      {step.event_type === 'wait_for_delivery_state' && (
                        <>
                          <label
                            htmlFor={`stages.${stageIndex}.steps.${stepIndex}.device_is_delivering`}
                            className="form-label pe-5"
                          >
                            Device is *
                          </label>
                          <Controller
                            control={control}
                            name={`stages.${stageIndex}.steps.${stepIndex}.device_is_delivering`}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                              return (
                                <Select
                                  onChange={(option) => {
                                    clearErrors(`stages.${stageIndex}.steps.${stepIndex}.device_is_delivering`);
                                    onChange(option?.value);

                                    switch (option?.value) {
                                      case 'Delivering':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });
                                      case 'Not Delivering':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });
                                      case 'Occluding':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });

                                      default:
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: null,
                                        });
                                    }
                                  }}
                                  options={(['Delivering', 'Occluding', 'Not Delivering'] as const).map((value) => ({
                                    value,
                                    label: value,
                                  }))}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  maxMenuHeight={300}
                                  onBlur={onBlur}
                                  ref={ref}
                                  value={value ? { value, label: value } : null}
                                  isClearable
                                />
                              );
                            }}
                          />

                          {
                            //@ts-ignore
                            stepErrorObject?.device_is_delivering?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Device Status is required</div>
                            )
                          }
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
        <button
          className={`btn btn-primary mt-2 ${!stepsFields.length ? '' : 'ms-5'}`}
          onClick={() => appendStep(DEFAULT_PROGRAM_DELIVERY_STEP)}
          type="button"
        >
          Add Step
        </button>
      </div>
    </>
  );
};

export default CreateTestProtocolForm;
