export const LAB_MENU = {
  title: 'Test Lab Administration',
  icon: 'LAB',
  items: [
    {
      title: 'Lab Monitor',
      link: '/lab-monitor',
      icon: 'LAB_MONITOR',
    },
    {
      title: 'Test Scheduler',
      link: '/test-data',
      icon: 'TEST_SCHEDULER',
    },
    {
      title: 'Configure Equipment',
      link: '/configure-equipment',
      icon: 'CONFIGURE_EQUIPMENT',
    },
  ],
};
