import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import DecimalInput from 'components/UI/DecimalInput';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { ScheduleType } from 'constants/schedule-type';
import { parseErrorMessage } from 'helpers/parse-error-message';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';
import { TestRun } from 'interfaces/equipment/test-run';
import { TestProtocol } from 'interfaces/tests-scheduler/test-protocol';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider, useFormContext, Controller, useFieldArray } from 'react-hook-form';
import toast from 'react-hot-toast';
import { IoMdTrash } from 'react-icons/io';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Tooltip } from 'react-tooltip';
import TestProtocolService from 'services/TestProtocolService';
import TestScheduleService from 'services/TestScheduleService';
import {
  DEFAULT_DIVERT_STEP,
  DEFAULT_PROGRAM_DELIVERY_STEP,
  DEFAULT_STOP_DELIVERY_STEP,
  DEFAULT_WAIT_FOR_EVENT_STEP,
  StagesForm,
  WaitForEventStepForm,
} from '../TestProtocols/TestProtocolForm';
import { parseProtocolParameters } from 'helpers/parse-protocols-parameters';
import TACService from 'services/TACService';
import TestGroupService from 'services/TestGroupService';
import { TestGroup } from 'interfaces/equipment/test-group';

export type TestSchedulerFormData = {
  name: string;
  description: string | null;
  test_series: number | null;

  schedule_type: { label: string; value: ScheduleType } | null;
  schedule_parameters: StagesForm[] | null;

  tests_per_device: number | null;

  test_runs: TestRun[];
  test_automation_controllers: { label: TestAutomationController['name']; value: TestAutomationController['id'] }[];
  grouped_tacs: { label: TestGroup['name']; value: TestGroup }[];

  test_protocol_id: { label: TestProtocol['name']; value: TestProtocol['id'] } | null;
};

const BACK_URL = '/test-data/test-schedule';

const defaultData: TestSchedulerFormData = {
  name: '',
  description: null,
  test_protocol_id: null,
  schedule_parameters: null,
  tests_per_device: null,
  test_series: null,
  schedule_type: null,
  test_runs: [],
  test_automation_controllers: [],
  grouped_tacs: [],
};

const formatLabel = (val: string) => {
  return val.charAt(0) + val.slice(1).toLowerCase();
};

const TestScheduleForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const testScheduleIdToBeUpdated = Number(searchParams.get('add'));

  //if creating a record, 'add' value is a boolean (that turns into Nan when forced into a number), if updating 'add' value is a number
  const isCreating = isNaN(testScheduleIdToBeUpdated);
  const methods = useForm<TestSchedulerFormData>({
    mode: 'all',
    defaultValues: async () => {
      if (isCreating) {
        return defaultData;
      } else {
        try {
          const { data } = await TestScheduleService.find(testScheduleIdToBeUpdated);
          return {
            ...data,
            test_runs: data.test_runs ?? [],
            test_automation_controllers:
              data.test_automation_controllers?.map((tac) => {
                return { label: tac.name, value: tac.id };
              }) ?? [],
            test_protocol_id: data.test_protocol
              ? { label: data.test_protocol.name, value: data.test_protocol.id }
              : null,
            schedule_type: data.schedule_type
              ? { label: formatLabel(data.schedule_type), value: data.schedule_type }
              : null,
            grouped_tacs: [],
          };
        } catch (error) {
          toast.error('The Test Schedule could not be loaded.');
          navigate(BACK_URL);
          return defaultData;
        }
      }
    },
  });

  const {
    handleSubmit,
    formState: { isLoading, isSubmitting },
  } = methods;

  const steps = [<Step1 key="step1" />, <Step2 key="step2" />, <Step3 key="step3" />];

  const isLastStep = currentStep === steps.length - 1;

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleSubmitData = async (formData: TestSchedulerFormData) => {
    if (isLastStep) {
      const tac_assignments = [
        ...formData.test_automation_controllers.map((tac) => tac.value),
        //deestructure the TacsIds of every test group
        ...formData.grouped_tacs.reduce(
          (allTacs, testGroup) => {
            const maybeTacs = testGroup?.value.test_automation_controllers;
            if (Array.isArray(maybeTacs) && !!maybeTacs.length) {
              allTacs.push(...maybeTacs.map((tac) => tac.id));
            }
            return allTacs;
          },
          [] as TestAutomationController['id'][]
        ),
      ];

      const { test_automation_controllers, ...restFormData } = formData;
      const data = {
        ...restFormData,
        name: formData.name.trim(),
        description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
        test_series: Number(formData.test_series),
        tests_per_device: Number(formData.tests_per_device),
        test_protocol_id: Number(formData.test_protocol_id?.value),
        schedule_parameters: parseProtocolParameters(formData.schedule_parameters),
        schedule_type: formData.schedule_type?.value,
        tac_assignments,
      };

      if (isCreating) {
        try {
          await TestScheduleService.create(data);

          toast.success('The Test Schedule was successfully created!');
          queryClient.invalidateQueries({
            queryKey: [ReactQueryKeys.TEST_SCHEDULER],
          });
          navigate(BACK_URL);
        } catch (e: any) {
          let message = 'The Test Schedule could not be created. Please try again.';
          if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
            message = parseErrorMessage(e.response.data);
          }
          toast.error(message);
        }
      } else {
        try {
          await TestScheduleService.update(testScheduleIdToBeUpdated, data);

          toast.success('The Test Schedule was successfully updated!');
          queryClient.invalidateQueries({
            queryKey: [ReactQueryKeys.TEST_SCHEDULER],
          });
          navigate(BACK_URL);
        } catch (e: any) {
          let message = 'The Test Schedule could not be updated. Please try again.';
          if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
            message = parseErrorMessage(e.response.data);
          }
          toast.error(message);
        }
      }
    } else {
      nextStep();
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleSubmitData)}>
        {steps[currentStep]}

        <div className="d-flex mt-3">
          {currentStep > 0 && (
            <button className="btn btn-secondary btn-lg" type="button" onClick={prevStep}>
              Back
            </button>
          )}
          <Button
            className="ms-auto"
            loading={isSubmitting}
            defaultLabel={!isLastStep ? 'Next' : isCreating ? 'Save' : 'Update'}
            loadingLabel={isCreating ? 'Saving' : 'Updating'}
            type="submit"
            disabled={isLoading || isSubmitting}
          />
        </div>
      </form>
    </FormProvider>
  );
};

const Step2 = () => {
  const {
    register,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useFormContext<TestSchedulerFormData>();

  const protocolSelected = watch('test_protocol_id.value');

  const { isSuccess, data } = useQuery({
    queryKey: [ReactQueryKeys.TEST_PROTOCOLS, protocolSelected],
    queryFn: () => TestProtocolService.find(protocolSelected),
    placeholderData: keepPreviousData,
    enabled: !!protocolSelected,
  });

  useEffect(() => {
    isSuccess && setValue('schedule_parameters', data.data.parameters);
  }, [data?.data.parameters]);

  const {
    fields: stagesFields,
    append,
    remove,
  } = useFieldArray<TestSchedulerFormData>({ name: 'schedule_parameters', control });

  return (
    <div>
      <h2>Step 2</h2>
      <div className="row py-2">
        {/* Test Schedule Name */}
        <div className="col-6">
          <label htmlFor="name" className="form-label">
            Test Schedule Name *
          </label>
          <input
            type="text"
            autoFocus
            className="form-control form-control-lg rounded"
            id="name"
            data-testid="name"
            {...register('name', {
              required: true,
              maxLength: 32,
              minLength: 3,
            })}
          />
          {errors?.name?.type === 'required' && (
            <div className="invalid-feedback pt-1">Test Schedule Name is required</div>
          )}
          {errors?.name?.type === 'maxLength' && (
            <div className="invalid-feedback pt-1">Test Schedule Name must have maximum 32 characters</div>
          )}
          {errors?.name?.type === 'minLength' && (
            <div className="invalid-feedback pt-1">Test Schedule Name must have minimum 3 characters</div>
          )}
        </div>
        {/* Note */}
        <div className="col-6">
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>
      <div className="row py-2">
        {/* Tests Series */}
        <div className="col-6">
          <label htmlFor={`test_series`} className="form-label">
            Tests Series*
          </label>
          <Controller
            control={control}
            name={`test_series`}
            rules={{
              required: true,
              min: 0,
              max: 999999,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                className={'form-control form-control-lg rounded'}
                id={`test_series`}
                data-testid={`test_series`}
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={0}
                ref={ref}
                alignCenter={false}
              />
            )}
          />
          {errors?.test_series?.type === 'required' && (
            <div className="invalid-feedback pt-1">Tests Series is required</div>
          )}
          {(errors?.test_series?.type === 'min' || errors?.tests_per_device?.type === 'max') && (
            <div className="invalid-feedback pt-1">Tests Series value should be between 0 and 999999</div>
          )}
        </div>
        {/* Tests per Device */}
        <div className="col-6">
          <label htmlFor={`tests_per_device`} className="form-label">
            Tests per Device*
          </label>
          <Controller
            control={control}
            name={`tests_per_device`}
            rules={{
              required: true,
              min: 0,
              max: 9600,
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DecimalInput
                className={'form-control form-control-lg rounded'}
                id={`tests_per_device`}
                data-testid={`tests_per_device`}
                value={String(value)}
                onChange={onChange}
                onBlur={onBlur}
                precision={0}
                ref={ref}
                alignCenter={false}
              />
            )}
          />
          {errors?.tests_per_device?.type === 'required' && (
            <div className="invalid-feedback pt-1">Tests per Device is required</div>
          )}
          {(errors?.tests_per_device?.type === 'min' || errors?.tests_per_device?.type === 'max') && (
            <div className="invalid-feedback pt-1">Tests per Device value should be between 0 and 9600</div>
          )}
        </div>
      </div>
      <div className="row">
        {/*  Schedule Type */}
        <div className="col-6">
          <label htmlFor={`schedule_type`} className="form-label pe-2">
            Schedule Type *
          </label>
          <Controller
            control={control}
            name={`schedule_type`}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                id={`schedule_type`}
                onChange={onChange}
                isMulti={false}
                isClearable
                options={Object.values(ScheduleType).map((val) => ({
                  value: val,
                  label: formatLabel(val),
                }))}
                className="react-select-container my-2"
                classNamePrefix="react-select"
                maxMenuHeight={300}
                onBlur={onBlur}
                ref={ref}
                value={value}
              />
            )}
          />

          {errors?.schedule_type?.type === 'required' && (
            <div className="invalid-feedback pt-1">Schedule Type is required</div>
          )}
        </div>
      </div>

      <div>
        <div className="py-4">
          <b>Test Protocol:</b> {data?.data.name}
        </div>
        <div className="card bg-primary mb-4">
          <div className="card-header bg-primary">
            <h5 className="card-title mb-0">Stages</h5>
          </div>
          <div className="bg-dark card-body">
            {stagesFields.map((stage, index) => {
              return (
                <div key={stage.id} className={`my-4 ${index ? 'top-line' : ''}`}>
                  <div className="d-flex mt-3">
                    <h5 className="flex-grow-1 " style={{ fontSize: '18px' }}>
                      {' '}
                      Stage index: {index + 1}
                    </h5>
                    <button
                      className="delete-icon"
                      data-tooltip-id={`delete-stage-${index}`}
                      data-tooltip-content={`Remove stage index: ${index + 1}`}
                    >
                      <IoMdTrash
                        onClick={() => {
                          remove(index);
                        }}
                      />
                    </button>
                    <Tooltip id={`delete-stage-${index}`} />
                  </div>

                  <div className="">
                    <div>
                      <label htmlFor={`schedule_parameters.${index}.name`} className="form-label">
                        Stage Name *
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-lg rounded"
                        id={`schedule_parameters.${index}.name`}
                        data-testid={`schedule_parameters.${index}.name`}
                        {...register(`schedule_parameters.${index}.name`, {
                          required: true,
                          maxLength: 32,
                          minLength: 3,
                        })}
                      />
                      {errors.schedule_parameters?.[index]?.name?.type === 'required' && (
                        <div className="invalid-feedback">Stage Name is required</div>
                      )}
                      {errors.schedule_parameters?.[index]?.name?.type === 'maxLength' && (
                        <div className="invalid-feedback pt-1">Stage Name must have maximum 32 characters</div>
                      )}
                      {errors.schedule_parameters?.[index]?.name?.type === 'minLength' && (
                        <div className="invalid-feedback pt-1">Stage Name must have minimum 3 characters</div>
                      )}
                    </div>
                    <StepsFieldArray stageIndex={index} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const STEPS_TYPE_ARRAY = ['Program Delivery', 'Stop Delivery', 'Diverter Command', 'Wait for Event'] as const;

const StepsFieldArray = ({ stageIndex }: { stageIndex: number }) => {
  const {
    register,
    watch,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<TestSchedulerFormData>();

  const {
    fields: stepsFields,
    append: appendStep,
    remove: removeStep,
    update: updateStep,
  } = useFieldArray({
    name: `schedule_parameters.${stageIndex}.steps`,
    control,
  });

  return (
    <>
      <div className="">
        {stepsFields.map((step, stepIndex) => {
          const stepErrorObject = errors.schedule_parameters?.[stageIndex]?.steps?.[stepIndex];
          return (
            <div key={step.id} className="mt-5 ps-5">
              <div className="d-flex">
                <h5 className="flex-grow-1 "> Step {stepIndex + 1}</h5>
                <button
                  onClick={() => {
                    removeStep(stepIndex);
                  }}
                  className="btn btn-secondary mt-2"
                >
                  Remove step
                </button>{' '}
              </div>

              <Controller
                control={control}
                name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.type`}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Select
                    onChange={(option) => {
                      clearErrors(`schedule_parameters.${stageIndex}.steps.${stepIndex}.type`);

                      onChange(option);

                      switch (option?.label) {
                        case 'Diverter Command':
                          return updateStep(stepIndex, DEFAULT_DIVERT_STEP);
                        case 'Program Delivery':
                          return updateStep(stepIndex, DEFAULT_PROGRAM_DELIVERY_STEP);
                        case 'Stop Delivery':
                          return updateStep(stepIndex, DEFAULT_STOP_DELIVERY_STEP);
                        case 'Wait for Event':
                          return updateStep(stepIndex, DEFAULT_WAIT_FOR_EVENT_STEP);
                        default:
                          return updateStep(stepIndex, { ...step, type: null });
                      }
                    }}
                    isClearable
                    isMulti={false}
                    options={STEPS_TYPE_ARRAY.map((type) => ({ value: type, label: type }))}
                    className="react-select-container my-2"
                    classNamePrefix="react-select"
                    maxMenuHeight={300}
                    onBlur={onBlur}
                    ref={ref}
                    value={value ? { value, label: value } : null}
                  />
                )}
              />
              {
                //@ts-ignore
                stepErrorObject?.type?.type === 'required' && (
                  <div className="invalid-feedback pt-1">Step Type is required</div>
                )
              }

              {step.type === 'Program Delivery' && (
                <>
                  <div className="row pt-4">
                    {/* Delivery Rate */}
                    <div className="col-6">
                      <label
                        htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                        className="form-label"
                      >
                        Delivery Rate *
                      </label>
                      <Controller
                        control={control}
                        name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                        rules={{
                          required: true,
                          min: 0,
                          max: 999999.99,
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DecimalInput
                            className={'form-control form-control-lg rounded'}
                            id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                            data-testid={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_rate`}
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            precision={2}
                            ref={ref}
                            alignCenter={false}
                            inputGroup={true}
                            inputGroupText="mL/hr"
                          />
                        )}
                      />
                      {
                        //@ts-ignore
                        stepErrorObject?.delivery_rate?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Delivery Rate is required</div>
                        )
                      }
                      {
                        //@ts-ignore
                        (stepErrorObject?.delivery_rate?.type === 'min' ||
                          //@ts-ignore
                          stepErrorObject?.delivery_rate?.type === 'max') && (
                          <div className="invalid-feedback pt-1">
                            Delivery Rate value should be between 0 and 999999.99
                          </div>
                        )
                      }
                    </div>
                    {/* Delivery VTBI */}
                    <div className="col-6">
                      <label
                        htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                        className="form-label"
                      >
                        Delivery VTBI *
                      </label>
                      <Controller
                        control={control}
                        name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                        rules={{
                          required: true,
                          min: 0,
                          max: 999999.99,
                        }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <DecimalInput
                            className={'form-control form-control-lg rounded'}
                            id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                            data-testid={`schedule_parameters.${stageIndex}.steps.${stepIndex}.delivery_vtbi`}
                            value={String(value)}
                            onChange={onChange}
                            onBlur={onBlur}
                            precision={2}
                            ref={ref}
                            alignCenter={false}
                            inputGroup={true}
                            inputGroupText="mL"
                          />
                        )}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.delivery_vtbi?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Delivery VTBI is required</div>
                        )
                      }
                      {
                        //@ts-ignore
                        (stepErrorObject?.delivery_vtbi?.type === 'min' ||
                          //@ts-ignore
                          stepErrorObject?.delivery_vtbi?.type === 'max') && (
                          <div className="invalid-feedback pt-1">
                            Delivery VTBI value should be between 0 and 999999.99
                          </div>
                        )
                      }
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Stop Delivery' && (
                <>
                  <div className="row pt-4">
                    {/*  Stop Delivery */}
                    <div className="col-12">
                      <label
                        htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                        className="form-label pe-5"
                      >
                        Stop Delivery
                      </label>
                      <Controller
                        control={control}
                        name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                        rules={{}}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Checkbox
                            disabled={true}
                            id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                            data-testid={`schedule_parameters.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                            title=""
                            checked={value}
                            ref={ref}
                            onBlur={onBlur}
                            onChange={onChange}
                            value={`schedule_parameters.${stageIndex}.steps.${stepIndex}.stop_delivery`}
                          />
                        )}
                      />
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Diverter Command' && (
                <>
                  <div className="row pt-4">
                    {/*  Diverter Command */}
                    <div className="col-6">
                      <label
                        htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                        className="form-label pe-2"
                      >
                        Command *
                      </label>
                      <Controller
                        control={control}
                        name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                          <Select
                            id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.diverter_cmd`}
                            onChange={(option) => {
                              clearErrors(`schedule_parameters.${stageIndex}.steps.${stepIndex}.diverter_cmd`);
                              onChange(option);
                              updateStep(stepIndex, {
                                ...step,
                                diverter_cmd: option?.value ?? null,
                              });
                            }}
                            isMulti={false}
                            isClearable
                            options={(['DIVERT', 'DELIVER'] as const).map((cmd) => ({
                              value: cmd,
                              label: cmd.charAt(0) + cmd.slice(1).toLowerCase(),
                            }))}
                            className="react-select-container my-2"
                            classNamePrefix="react-select"
                            maxMenuHeight={300}
                            onBlur={onBlur}
                            ref={ref}
                            value={value ? { value, label: value.charAt(0) + value.slice(1).toLowerCase() } : null}
                          />
                        )}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.diverter_cmd?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Command is required</div>
                        )
                      }
                    </div>
                  </div>
                </>
              )}

              {step.type === 'Wait for Event' && (
                <>
                  <div className="row pt-4 my-2">
                    {/*  Wait for Event */}
                    <div className="col-6">
                      <label
                        htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.event_type`}
                        className="form-label pe-2"
                      >
                        Event Type *
                      </label>
                      <Controller
                        control={control}
                        name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.event_type`}
                        rules={{ required: true }}
                        render={({ field: { onChange, onBlur, value, ref } }) => {
                          const WAIT_EVENT_LABELS: Record<Exclude<WaitForEventStepForm['event_type'], null>, string> = {
                            wait_for_alarm_state: 'Error/Alarms',
                            wait_for_time: 'Time Elapsed',
                            wait_for_volume: 'Volume Delivered',
                            wait_for_delivery_state: 'Device Status',
                          };
                          return (
                            <Select
                              onChange={(option) => {
                                clearErrors(`schedule_parameters.${stageIndex}.steps.${stepIndex}.event_type`);
                                onChange(option?.value);

                                switch (option?.value) {
                                  case 'wait_for_alarm_state':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      device_has_error_or_alarm: false,
                                    });
                                  case 'wait_for_delivery_state':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      device_is_delivering: null,
                                    });
                                  case 'wait_for_time':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      seconds_to_wait: null,
                                    });
                                  case 'wait_for_volume':
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: option.value,
                                      wait_for_ml: null,
                                    });

                                  default:
                                    return updateStep(stepIndex, {
                                      ...step,
                                      event_type: null,
                                    });
                                }
                              }}
                              options={(
                                [
                                  'wait_for_time',
                                  'wait_for_volume',
                                  'wait_for_delivery_state',
                                  'wait_for_alarm_state',
                                ] as const
                              ).map((value) => ({ value, label: WAIT_EVENT_LABELS[value] }))}
                              className="react-select-container"
                              classNamePrefix="react-select"
                              maxMenuHeight={300}
                              onBlur={onBlur}
                              ref={ref}
                              value={value ? { value, label: WAIT_EVENT_LABELS[value] } : null}
                              isClearable
                            />
                          );
                        }}
                      />

                      {
                        //@ts-ignore
                        stepErrorObject?.event_type?.type === 'required' && (
                          <div className="invalid-feedback pt-1">Event Type is required</div>
                        )
                      }
                    </div>
                    <div className="col-6 mt-auto">
                      {step.event_type === 'wait_for_volume' && (
                        <>
                          <label
                            htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                            className="form-label"
                          >
                            Wait for (x) mL *
                          </label>
                          <Controller
                            control={control}
                            name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                            rules={{
                              required: true,
                              min: 0,
                              max: 999999.99,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DecimalInput
                                className={'form-control form-control-lg rounded'}
                                id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                                data-testid={`schedule_parameters.${stageIndex}.steps.${stepIndex}.wait_for_ml`}
                                value={String(value)}
                                onChange={onChange}
                                onBlur={onBlur}
                                precision={2}
                                ref={ref}
                                alignCenter={false}
                                inputGroup={true}
                                inputGroupText="mL"
                              />
                            )}
                          />
                          {
                            //@ts-ignore
                            stepErrorObject?.wait_for_ml?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Volume Delivered is required</div>
                            )
                          }
                          {
                            //@ts-ignore
                            (stepErrorObject?.wait_for_ml?.type === 'min' ||
                              //@ts-ignore
                              stepErrorObject?.wait_for_ml?.type === 'max') && (
                              <div className="invalid-feedback pt-1">
                                Volume Delivered value should be between 0 and 999999.99
                              </div>
                            )
                          }
                        </>
                      )}

                      {step.event_type === 'wait_for_time' && (
                        <>
                          <label
                            htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                            className="form-label"
                          >
                            Wait Duration *
                          </label>
                          <Controller
                            control={control}
                            name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                            rules={{
                              required: true,
                              min: 1,
                              max: 99999,
                            }}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DecimalInput
                                className={'form-control form-control-lg rounded'}
                                id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                                data-testid={`schedule_parameters.${stageIndex}.steps.${stepIndex}.seconds_to_wait`}
                                value={String(value)}
                                onChange={onChange}
                                onBlur={onBlur}
                                precision={0}
                                ref={ref}
                                alignCenter={false}
                                inputGroup={true}
                                inputGroupText="seconds"
                              />
                            )}
                          />
                          {
                            //@ts-ignore
                            stepErrorObject?.seconds_to_wait?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Wait Duration is required</div>
                            )
                          }
                          {
                            //@ts-ignore
                            (stepErrorObject?.seconds_to_wait?.type === 'min' ||
                              //@ts-ignore
                              stepErrorObject?.seconds_to_wait?.type === 'max') && (
                              <div className="invalid-feedback pt-1">
                                Wait Duration value should be between 1 and 99999
                              </div>
                            )
                          }
                        </>
                      )}

                      {step.event_type === 'wait_for_alarm_state' && (
                        <>
                          <label
                            htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                            className="form-label pe-5"
                          >
                            Device has error or alarm
                          </label>
                          <Controller
                            control={control}
                            name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                            rules={{}}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <Checkbox
                                id={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                                checked={value}
                                ref={ref}
                                onBlur={onBlur}
                                onChange={onChange}
                                value={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_has_error_or_alarm`}
                              />
                            )}
                          />
                        </>
                      )}

                      {step.event_type === 'wait_for_delivery_state' && (
                        <>
                          <label
                            htmlFor={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_is_delivering`}
                            className="form-label pe-5"
                          >
                            Device is *
                          </label>
                          <Controller
                            control={control}
                            name={`schedule_parameters.${stageIndex}.steps.${stepIndex}.device_is_delivering`}
                            rules={{ required: true }}
                            render={({ field: { onChange, onBlur, value, ref } }) => {
                              return (
                                <Select
                                  onChange={(option) => {
                                    clearErrors(
                                      `schedule_parameters.${stageIndex}.steps.${stepIndex}.device_is_delivering`
                                    );
                                    onChange(option?.value);

                                    switch (option?.value) {
                                      case 'Delivering':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });
                                      case 'Not Delivering':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });
                                      case 'Occluding':
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: option.value,
                                        });

                                      default:
                                        return updateStep(stepIndex, {
                                          ...step,
                                          device_is_delivering: null,
                                        });
                                    }
                                  }}
                                  options={(['Delivering', 'Occluding', 'Not Delivering'] as const).map((value) => ({
                                    value,
                                    label: value,
                                  }))}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  maxMenuHeight={300}
                                  onBlur={onBlur}
                                  ref={ref}
                                  value={value ? { value, label: value } : null}
                                  isClearable
                                />
                              );
                            }}
                          />

                          {
                            //@ts-ignore
                            stepErrorObject?.device_is_delivering?.type === 'required' && (
                              <div className="invalid-feedback pt-1">Device Status is required</div>
                            )
                          }
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          );
        })}
        <button
          className={`btn btn-primary mt-2 ${!stepsFields.length ? '' : 'ms-5'}`}
          onClick={() => appendStep(DEFAULT_PROGRAM_DELIVERY_STEP)}
          type="button"
        >
          Add Step
        </button>
      </div>
    </>
  );
};

const Step1 = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TestSchedulerFormData>();

  /**
   * Filter list of Test Protocols
   *
   * @param {string} inputValue The value that was entered for filtering tacs
   * @returns List of ungrouped TACs
   */
  const testProtocolOptions = (inputValue: string) =>
    TestProtocolService.get({
      pageIndex: 0,
      pageSize: 20,
      columnFilters: [
        //TODO: copy this from TestGroupsModal and service in BE
        // { id: 'test_group_id', value: 'null' },
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  return (
    <div>
      <h2>Step 1</h2>
      <div className="row pt-4">
        {/* Test Protocol Id */}
        <div className="col-12">
          <label htmlFor="test_protocol_id" className="form-label">
            Test Protocol
          </label>
          <Controller
            control={control}
            name="test_protocol_id"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AsyncSelect
                id="test_protocol_id"
                maxMenuHeight={300}
                onChange={onChange}
                onBlur={onBlur}
                ref={ref}
                value={value}
                isMulti={false}
                placeholder="Search..."
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable
                isSearchable
                options={[]}
                cacheOptions={false}
                // @ts-ignore
                loadOptions={testProtocolOptions}
                defaultOptions
              />
            )}
          />
          {errors.test_protocol_id?.type === 'required' && (
            <div className="invalid-feedback pt-1">Test Protocol is required</div>
          )}
        </div>
      </div>
    </div>
  );
};

const Step3 = () => {
  const {
    control,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<TestSchedulerFormData>();

  const tacGroups = watch('grouped_tacs');
  const individualTacs = watch('test_automation_controllers');
  /**
   * Filter list of group TACs
   *
   * @param {string} inputValue The value that was entered for filtering Test Groups
   * @returns List of Test Groups grouped TACs
   */
  const groupedTacOptions = (inputValue: string) =>
    TestGroupService.get({
      pageIndex: 0,
      pageSize: 20,
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record) => {
        return {
          label: record.name,
          value: record,
        };
      });
    });

  /**
   * Filter list of individual TACs
   *
   * @param {string} inputValue The value that was entered for filtering tacs
   * @returns List of ungrouped TACs
   */
  const singleTacOptions = (inputValue: string) =>
    TACService.get({
      pageIndex: 0,
      pageSize: 20,
      columnFilters: [
        { id: 'test_group_id', value: 'null' },
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  const ERROR_TAC_KEY = 'checkAtLeastOneTacSelected';

  return (
    <div>
      <h2>Step 3</h2>
      <div className="row pt-4">
        {/* Grouped TACs*/}
        <div className="col-6">
          <label htmlFor="grouped_tacs" className="form-label">
            Add TAC Group
          </label>
          <Controller
            control={control}
            name="grouped_tacs"
            rules={{
              validate: {
                [ERROR_TAC_KEY]: (groupedTacs, formData) => {
                  return !!groupedTacs.length || !!formData.test_automation_controllers.length;
                },
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AsyncSelect
                id="grouped_tacs"
                maxMenuHeight={300}
                onChange={(val) => {
                  onChange(val);
                  trigger('test_automation_controllers');
                }}
                onBlur={onBlur}
                ref={ref}
                value={value}
                isMulti={true}
                placeholder="Search..."
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable
                isSearchable
                options={[]}
                cacheOptions={false}
                // @ts-ignore
                loadOptions={groupedTacOptions}
                defaultOptions
              />
            )}
          />
        </div>
        {/* Individual TACs*/}
        <div className="col-6">
          <label htmlFor="test_automation_controllers" className="form-label">
            Add Individual TAC
          </label>
          <Controller
            control={control}
            name="test_automation_controllers"
            rules={{
              validate: {
                [ERROR_TAC_KEY]: (singleTacs, formData) => {
                  return !!singleTacs.length || !!formData.grouped_tacs.length;
                },
              },
            }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <AsyncSelect
                id="test_automation_controllers"
                maxMenuHeight={300}
                onChange={(val) => {
                  onChange(val);
                  trigger('grouped_tacs');
                }}
                onBlur={onBlur}
                ref={ref}
                value={value}
                isMulti={true}
                placeholder="Search..."
                className="react-select-container"
                classNamePrefix="react-select"
                isClearable
                isSearchable
                options={[]}
                cacheOptions={false}
                // @ts-ignore
                loadOptions={singleTacOptions}
                defaultOptions
              />
            )}
          />
        </div>
        {(errors.test_automation_controllers?.type === ERROR_TAC_KEY ||
          errors.grouped_tacs?.type === ERROR_TAC_KEY) && (
          <div className="invalid-feedback">At least one TAC group or individual TAC is required.</div>
        )}
      </div>

      <div>
        {tacGroups.map((testGroup) => {
          return (
            <div className="card bg-primary my-4" key={testGroup.label + testGroup.value.id}>
              <div className="card-header bg-primary">
                <h5 className="card-title mb-0">TAC Group {testGroup.label}</h5>
              </div>
              <div className="bg-dark card-body">
                {' '}
                <div>
                  {testGroup.value.test_automation_controllers?.map((tac) => {
                    return (
                      <div className="py-2" key={tac.name + tac.id}>
                        {tac.name}
                      </div>
                    );
                  })}
                </div>{' '}
              </div>{' '}
            </div>
          );
        })}
        {!!individualTacs.length && (
          <div className="card bg-primary my-4">
            <div className="card-header bg-primary">
              <h5 className="card-title mb-0">Individual TACS</h5>
            </div>
            <div className="bg-dark card-body">
              {individualTacs?.map((tac) => {
                return (
                  <div className="py-2" key={tac.label + tac.value}>
                    {tac.label}
                  </div>
                );
              })}
              <div></div>{' '}
            </div>{' '}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestScheduleForm;
