import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import { IoIosAdd, IoMdClose } from 'react-icons/io';
import { Link, Outlet, useSearchParams } from 'react-router-dom';
import { TEST_SCHEDULER_LINKS } from 'constants/test-scheduler-links';
import { TestSchedule } from 'interfaces/tests-scheduler/test-scheduler';
import { useMemo, useState } from 'react';
import { ReactQueryKeys } from 'constants/react-query-keys';
import TestScheduleService from 'services/TestScheduleService';
import { formatDateLong } from 'helpers/date-helpers';
import TestScheduleForm from './TestScheduleForm';

const TestSchedules = () => {
  const [searchParams] = useSearchParams();
  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<TestSchedule>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <div data-testid={`name_${row?.original?.id}`}>{`${row.original.name}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Note',
        cell: ({ row }) => (
          <div data-testid={`description_${row?.original?.id}`}>{`${row?.original?.description ?? ''}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Updated by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => row?.original?.updated_by_user?.name,
      },
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: 'Update date',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div data-testid={`updated_at_${row?.original?.id}`}>{formatDateLong(row?.original?.updated_at)}</div>
        ),
      },
      {
        id: 'schedule_parameters',
        accessorKey: 'schedule_parameters',
        header: 'Stages',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) =>
          !row?.original?.id ? null : (
            <div data-testid={`schedule_parameters_${row?.original?.id}`}>
              {JSON.stringify(row.original.schedule_parameters, null, 2)}
            </div>
          ),
      },
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.TEST_SCHEDULER, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      TestScheduleService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<TestSchedule> = useReactTable({
    data: dataQuery?.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  const isAddingSchedule = searchParams.get('add');
  return (
    <>
      <div>
        <GroupBox
          title="Test Scheduler"
          summary={[]}
          addBtn={
            isAddingSchedule ? (
              <Link to="/test-data/test-schedule" className="btn-rounded my-2">
                <IoMdClose size={20} />
              </Link>
            ) : (
              <Link to="/test-data/test-schedule?add=true" className="btn-rounded my-2" data-testid="add-btn">
                <IoIosAdd size={40} />
              </Link>
            )
          }
        >
          <div className="d-flex flex-row">
            <SideTabsLinks links={TEST_SCHEDULER_LINKS} />
            <div className="flex-grow-1 side-tabs__content">
              {searchParams.get('add') ? (
                <TestScheduleForm />
              ) : (
                <>
                  <Table classes="users-table" isFetching={dataQuery.isFetching} table={table} />
                  <Pagination table={table} />
                </>
              )}
            </div>
          </div>
        </GroupBox>
      </div>
      <Outlet />
    </>
  );
};

export default TestSchedules;
