import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import InfusionDeviceService from 'services/InfusionDeviceService';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { InfusionDevice } from 'interfaces/equipment/infusion-device';
import AsyncSelect from 'react-select/async';
import TACService from 'services/TACService';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';

const BACK_URL = '/configure-equipment/infusion-devices';

type FormData = {
  serial_number: string;
  description: string | null;
  test_automation_controller_id?: number | null;
  test_automation_controller?: { label: string; value: string } | null;
};

const defaultData: FormData = {
  serial_number: '',
  description: null,
  test_automation_controller_id: null,
  test_automation_controller: null,
};

const InfusionDevicesModalForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultData },
  });

  useEffect(() => {
    dispatch(overlayActions.open({ path: BACK_URL }));

    if (params.id) {
      setFormLoading(true);
      InfusionDeviceService.find(params.id)
        .then(({ data }: { data: InfusionDevice }) => {
          setValue('serial_number', data.serial_number);
          setValue('description', data.description);

          if (data.test_automation_controller) {
            setValue('test_automation_controller', {
              label: String(data.test_automation_controller.name),
              value: String(data.test_automation_controller.id),
            });
          }

          setFormLoading(false);
        })
        .catch(() => {
          toast.error('The infusion device could not be loaded.');
          navigate(BACK_URL);
        });
    }
  }, []);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      serial_number: formData.serial_number.trim(),
      description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
      test_automation_controller_id: formData.test_automation_controller?.value
        ? +formData.test_automation_controller?.value
        : null,
    };

    if (params.id) {
      try {
        await InfusionDeviceService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_INFUSION_DEVICES],
        });
        toast.success('The infusion device was successfully updated!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The infusion device could not be updated. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await InfusionDeviceService.create(data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_INFUSION_DEVICES],
        });
        toast.success('The infusion device was successfully created!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The infusion device could not be created. Please try again.';
        if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  /**
   * Filter list of TACs that are not assigned
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of drugs
   */
  const tacOptions = (inputValue: string) =>
    TACService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
      has_infusion_device: false,
    }).then((response) => {
      return response.data.records.map((record: TestAutomationController) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (formLoading || isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading || formLoading ? <Spinner isAbsolute /> : null}

        <div className="row">
          {/* Serial Number */}
          <div className="col-12">
            <label htmlFor="serial_number" className="form-label">
              Serial Number *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="serial_number"
              data-testid="serial_number"
              {...register('serial_number', {
                required: true,
                minLength: 1,
                maxLength: 24,
                pattern: /^\d+$/,
              })}
            />
            {errors?.serial_number?.type === 'required' && (
              <div className="invalid-feedback pt-1">Serial Number is required</div>
            )}
            {errors?.serial_number?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Serial Number must have minimum 1 character</div>
            )}
            {errors?.serial_number?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Serial Number must have maximum 24 characters</div>
            )}
            {errors?.serial_number?.type === 'pattern' && (
              <div className="invalid-feedback pt-1">Serial Number must contain only digits</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          <div className="col-12">
            <label htmlFor="test_automation_controller" className="form-label">
              Paired TAC
            </label>
            <Controller
              control={control}
              name="test_automation_controller"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="test_automation_controller"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={tacOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Description */}
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={isSubmitting || isLoading || formLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${isSubmitting || isLoading || formLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default InfusionDevicesModalForm;
