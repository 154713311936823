import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';
import { AccessToken } from 'interfaces/settings/access-token';

const BASE_URL = '/v1/settings/access-tokens';

export type AccessTokenResponse = PaginationResponse<AccessToken>;

const AccessTokenService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<AccessTokenResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  create: (data: Partial<AccessToken>) => {
    return axiosInstance.post<AccessToken>(BASE_URL, data);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<AccessToken>(`${BASE_URL}/${id}`);
  },
};

export default AccessTokenService;
