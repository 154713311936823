import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { PaginationResponse } from 'interfaces/pagination-response';
import { TestProtocol } from 'interfaces/tests-scheduler/test-protocol';

const BASE_URL = '/v1/test-protocols';

export type TestProtocolResponse = PaginationResponse<TestProtocol>;

const TestProtocolService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
  }) => {
    return axiosInstance.get<TestProtocolResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  create: (data: Partial<TestProtocol>) => {
    return axiosInstance.post<TestProtocol>(BASE_URL, data);
  },
  update: (id: string | number, data: Partial<TestProtocol>) => {
    return axiosInstance.patch<TestProtocol>(`${BASE_URL}/${id}`, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<TestProtocol>(`${BASE_URL}/${id}`);
  },
};

export default TestProtocolService;
