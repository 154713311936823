import { useState, useMemo, useEffect, useCallback } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-hot-toast';
import {
  getCoreRowModel,
  useReactTable,
  PaginationState,
  ColumnDef,
  getSortedRowModel,
  getFilteredRowModel,
  SortingState,
  ColumnFiltersState,
  Table as ReactTable,
} from '@tanstack/react-table';
import TableActions from 'components/UI/TableActions';
import { ReactQueryKeys } from 'constants/react-query-keys';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import { formatDateLong } from 'helpers/date-helpers';
import AccessTokenService from 'services/AccessTokenService';
import { AccessToken } from 'interfaces/settings/access-token';
import SwalAlert, { firePreConfirmAlert } from 'components/UI/SwalAlert';
import { SweetAlertResult } from 'sweetalert2';

const AccessTokens = () => {
  const queryClient = useQueryClient();

  // Show alert for confirming of deletion a user
  const handleTableDeleteShow = (accessToken: AccessToken) => {
    firePreConfirmAlert({
      title: 'Delete Access Token',
      html: `Are you sure you want to delete <strong>${accessToken.api_key}</strong>`,
      preConfirm: () => {
        return AccessTokenService.delete(accessToken.id)
          .then(({ data }) => {
            return data;
          })
          .catch((error) => {
            SwalAlert.showValidationMessage(error?.response?.data?.message);
            //return false to prevent pop up from closing when running tests, check preconfirm fn
            return false;
          });
      },
    }).then((data: SweetAlertResult) => {
      if (data.isConfirmed) {
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.SETTINGS_ACCESS_TOKENS],
        });
        toast.success('The Access Token has been deleted successfully.');
      }
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMemoizedTableDeleteShow = useCallback(handleTableDeleteShow, [queryClient]);

  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<AccessToken>[]>(
    () => [
      {
        id: 'api_key',
        accessorKey: 'api_key',
        header: 'Partial Key ID',
        cell: ({ row }) => <div data-testid={`api_key_${row.original.api_key}`}>{row.original.api_key}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Description',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => row.original.description,
      },

      {
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Created by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{row?.original?.updated_by_user?.name || 'N/A'}</div>,
      },
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: 'Create date',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`updated_at_${row?.original?.id}`}>{formatDateLong(row?.original?.updated_at)}</div>
            <TableActions
              onDelete={() => {
                handleMemoizedTableDeleteShow(row.original);
              }}
            />
          </div>
        ),
      },
    ],
    [handleMemoizedTableDeleteShow]
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.SETTINGS_ACCESS_TOKENS, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      AccessTokenService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<AccessToken> = useReactTable({
    data: dataQuery.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <Table classes="react-table" isFetching={dataQuery.isFetching} table={table} />
      <Pagination table={table} />
      <Outlet />
    </>
  );
};

export default AccessTokens;
