import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import TACService from 'services/TACService';

import { useEffect, useState } from 'react';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';
import AsyncSelect from 'react-select/async';
import TestGroupService from 'services/TestGroupService';
import { TestGroup } from 'interfaces/equipment/test-group';
import InfusionDeviceService from 'services/InfusionDeviceService';
import { InfusionDevice } from 'interfaces/equipment/infusion-device';
import IVSetService from 'services/IVSetService';
import { IVSet } from 'interfaces/equipment/iv-set';
import BalanceService from 'services/BalanceService';
import { Balance } from 'interfaces/equipment/balance';
import FluidTypeService from 'services/FluidTypeService';
import { FluidType } from 'interfaces/equipment/fluid-type';
import FluidSourceService from 'services/FluidSourceService';
import { FluidSource } from 'interfaces/equipment/fluid-source';
import NeedleTypeService from 'services/NeedleTypeService';
import { NeedleType } from 'interfaces/equipment/needle-type';
import ContainerTypeService from 'services/ContainerTypeService';
import { ContainerType } from 'interfaces/equipment/container-type';

const BACK_URL = '/configure-equipment/test-automation-controllers';

type FormData = {
  name: string;
  description: string | null;
  infusion_device_id: number | null;
  infusion_device?: { label: string; value: string } | null;
  balance_id: number | null;
  balance?: { label: string; value: string } | null;
  iv_set_id: number | null;
  iv_set?: { label: string; value: string } | null;
  fluid_type_id: number | null;
  fluid_type?: { label: string; value: string } | null;
  fluid_source_id: number | null;
  fluid_source?: { label: string; value: string } | null;
  needle_type_id: number | null;
  needle_type?: { label: string; value: string } | null;
  container_type_id: number | null;
  container_type?: { label: string; value: string } | null;
  test_group_id: number | null;
  test_group?: { label: string; value: string } | null;
};

const defaultData: FormData = {
  name: '',
  description: null,
  infusion_device_id: null,
  infusion_device: null,
  iv_set_id: null,
  iv_set: null,
  fluid_type_id: null,
  fluid_type: null,
  fluid_source_id: null,
  fluid_source: null,
  needle_type_id: null,
  needle_type: null,
  container_type_id: null,
  container_type: null,
  test_group_id: null,
  test_group: null,
  balance_id: null,
  balance: null,
};

const TestAutomationControllersModalForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const [formLoading, setFormLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultData },
  });

  useEffect(() => {
    dispatch(overlayActions.open({ path: BACK_URL }));

    if (params.id) {
      setFormLoading(true);
      TACService.find(params.id)
        .then(({ data }: { data: TestAutomationController }) => {
          setValue('name', data.name);
          setValue('description', data.description);

          if (data.infusion_device) {
            setValue('infusion_device', {
              label: String(data.infusion_device.serial_number),
              value: String(data.infusion_device.id),
            });
          }

          if (data.iv_set) {
            setValue('iv_set', {
              label: String(data.iv_set.name),
              value: String(data.iv_set.id),
            });
          }

          if (data.fluid_type) {
            setValue('fluid_type', {
              label: String(data.fluid_type.name),
              value: String(data.fluid_type.id),
            });
          }

          if (data.fluid_source) {
            setValue('fluid_source', {
              label: String(data.fluid_source.name),
              value: String(data.fluid_source.id),
            });
          }

          if (data.needle_type) {
            setValue('needle_type', {
              label: String(data.needle_type.name),
              value: String(data.needle_type.id),
            });
          }

          if (data.container_type) {
            setValue('container_type', {
              label: String(data.container_type.name),
              value: String(data.container_type.id),
            });
          }

          if (data.test_group) {
            setValue('test_group', {
              label: String(data.test_group.name),
              value: String(data.test_group.id),
            });
          }

          if (data.balance) {
            setValue('balance', {
              label: String(data.balance.name),
              value: String(data.balance.id),
            });
          }

          setFormLoading(false);
        })
        .catch(() => {
          toast.error('The Test Automation Controller could not be loaded.');
          navigate(BACK_URL);
        });
    }
  }, []);

  /**
   * Submit form and create TAC
   *
   * @param formData TAC data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      name: formData.name.trim(),
      description: formData.description === '' || formData.description === null ? null : formData.description?.trim(),
      infusion_device_id: formData.infusion_device?.value ? +formData.infusion_device?.value : null,
      iv_set_id: formData.iv_set?.value ? +formData.iv_set?.value : null,
      fluid_type_id: formData.fluid_type?.value ? +formData.fluid_type?.value : null,
      fluid_source_id: formData.fluid_source?.value ? +formData.fluid_source?.value : null,
      needle_type_id: formData.needle_type?.value ? +formData.needle_type?.value : null,
      container_type_id: formData.container_type?.value ? +formData.container_type?.value : null,
      test_group_id: formData.test_group?.value ? +formData.test_group?.value : null,
      balance_id: formData.balance?.value ? +formData.balance?.value : null,
    };

    if (params.id) {
      try {
        await TACService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_TAC],
        });
        toast.success('The Test Automation Controller was successfully updated!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The Test Automation Controller could not be updated. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await TACService.create(data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_TAC],
        });
        toast.success('The Test Automation Controller was successfully created!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The Test Automation Controller could not be created. Please try again.';
        if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  /**
   * Filter list of Test Groups
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of Test Groups
   */
  const testGroupOptions = (inputValue: string) =>
    TestGroupService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: TestGroup) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  /**
   * Filter list of Infusion Devices
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of Infusion Devices
   */
  const infusionDeviceOptions = (inputValue: string) =>
    InfusionDeviceService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'serial_number',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'serial_number',
          value: inputValue,
        },
      ],
      has_tac: false,
    }).then((response) => {
      return response.data.records.map((record: InfusionDevice) => {
        return {
          label: record.serial_number,
          value: record.id,
        };
      });
    });
  /**
   * Filter list of IV Sets
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of IV Sets
   */
  const ivSetOptions = (inputValue: string) =>
    IVSetService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
      has_tac: false,
    }).then((response) => {
      return response.data.records.map((record: IVSet) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });
  /**
   * Filter list of Balances
   *
   * @param {string} inputValue The value that was entered for filtering drugs
   * @returns List of Balances
   */
  const balanceOptions = (inputValue: string) =>
    BalanceService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
      has_tac: false,
    }).then((response) => {
      return response.data.records.map((record: Balance) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });
  /**
   * Filter list of Fluid Types
   *
   * @param {string} inputValue The value that was entered for filtering fluid types
   * @returns List of Fluid Types
   */
  const fluidTypeOptions = (inputValue: string) =>
    FluidTypeService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: FluidType) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });
  /**
   * Filter list of Fluid Sources
   *
   * @param {string} inputValue The value that was entered for filtering Fluid Sources
   * @returns List of Fluid Sources
   */
  const fluidSourceOptions = (inputValue: string) =>
    FluidSourceService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: FluidSource) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  /**
   * Filter list of Needle Type
   *
   * @param {string} inputValue The value that was entered for filtering Needle Type
   * @returns List of Needle Type
   */
  const needleTypeOptions = (inputValue: string) =>
    NeedleTypeService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: NeedleType) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  /**
   * Filter list of Container Type
   *
   * @param {string} inputValue The value that was entered for filtering Container Type
   * @returns List of Container Type
   */
  const containerTypeOptions = (inputValue: string) =>
    ContainerTypeService.get({
      pageIndex: 0,
      pageSize: 20,
      // sorting: [
      //   {
      //     id: 'name',
      //     desc: false,
      //   },
      // ],
      columnFilters: [
        {
          id: 'name',
          value: inputValue,
        },
      ],
    }).then((response) => {
      return response.data.records.map((record: ContainerType) => {
        return {
          label: record.name,
          value: record.id,
        };
      });
    });

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (formLoading || isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading && <Spinner isAbsolute />}
        <div className="row">
          {/* Test Automation Controller Name */}
          <div className="col-12">
            <label htmlFor="name" className="form-label">
              Test Automation Controller Name *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="name"
              data-testid="name"
              {...register('name', {
                required: true,
                maxLength: 100,
                minLength: 1,
              })}
            />
            {errors?.name?.type === 'required' && (
              <div className="invalid-feedback pt-1">Test Automation Controller Name is required</div>
            )}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">
                Test Automation Controller Name must have maximum 100 characters
              </div>
            )}
            {errors?.name?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Test Automation Controller Name must have minimum 1 character</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Test Group */}
          <div className="col-6">
            <label htmlFor="test_group" className="form-label">
              Device Group
            </label>
            <Controller
              control={control}
              name="test_group"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="test_group"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={testGroupOptions}
                />
              )}
            />
          </div>
          {/* Infusion Device */}
          <div className="col-6">
            <label htmlFor="infusion_device" className="form-label">
              Paired Infusion Device
            </label>
            <Controller
              control={control}
              name="infusion_device"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="infusion_device"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={infusionDeviceOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* IV Set */}
          <div className="col-6">
            <label htmlFor="iv_set" className="form-label">
              IV Set
            </label>
            <Controller
              control={control}
              name="iv_set"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="iv_set"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={ivSetOptions}
                />
              )}
            />
          </div>
          {/* Balance */}
          <div className="col-6">
            <label htmlFor="balance" className="form-label">
              Balance
            </label>
            <Controller
              control={control}
              name="balance"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="balance"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={balanceOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Fluid Type */}
          <div className="col-6">
            <label htmlFor="fluid_type" className="form-label">
              Fluid Type
            </label>
            <Controller
              control={control}
              name="fluid_type"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="fluid_type"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={fluidTypeOptions}
                />
              )}
            />
          </div>
          {/* Fluid Source (bag) */}
          <div className="col-6">
            <label htmlFor="fluid_source" className="form-label">
              Fluid Source (bag)
            </label>
            <Controller
              control={control}
              name="fluid_source"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="fluid_source"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={fluidSourceOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Needle Type */}
          <div className="col-6">
            <label htmlFor="needle_type" className="form-label">
              Needle Type
            </label>
            <Controller
              control={control}
              name="needle_type"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="needle_type"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={needleTypeOptions}
                />
              )}
            />
          </div>
          {/* Container Type */}
          <div className="col-6">
            <label htmlFor="container_type" className="form-label">
              Container Type
            </label>
            <Controller
              control={control}
              name="container_type"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <AsyncSelect
                  id="container_type"
                  maxMenuHeight={300}
                  onChange={onChange}
                  onBlur={onBlur}
                  ref={ref}
                  value={value}
                  isMulti={false}
                  placeholder="Search..."
                  className="react-select-container"
                  classNamePrefix="react-select"
                  isClearable
                  isSearchable
                  options={[]}
                  cacheOptions={false}
                  // @ts-ignore
                  loadOptions={containerTypeOptions}
                />
              )}
            />
          </div>
        </div>

        <div className="row pt-4">
          {/* Note */}
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={formLoading || isSubmitting || isLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${formLoading || isSubmitting || isLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default TestAutomationControllersModalForm;
