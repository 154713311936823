import type { FC, MouseEventHandler } from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  clicked?: MouseEventHandler<HTMLButtonElement>;
  defaultLabel: string;
  loadingLabel?: string;
  loading?: boolean;
}

const Button: FC<ButtonProps> = ({
  defaultLabel,
  loadingLabel,
  clicked,
  loading = false,
  disabled = false,
  type = 'button',
  className,
  ...rest
}) => {
  return (
    <button
      onClick={clicked}
      className={'btn btn-lg rounded btn-primary me-3 ' + (className ?? '')}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {loading && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>}
      {loading ? loadingLabel : defaultLabel}
    </button>
  );
};
export default Button;
