import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import DecimalInput from 'components/UI/DecimalInput';
import BalanceService from 'services/BalanceService';
import Checkbox from 'components/UI/Checkbox';
import DatePicker from 'react-datepicker';
import { useEffect } from 'react';

const BACK_URL = '/configure-equipment/balances';

const validResolutions = [1.0, 0.1, 0.01, 0.001, 0.0001, 0.00001];

type FormData = {
  name: string;
  serial_number: string;
  model_number: string;
  manufacturer: string;
  max_capacity_g: number | null;
  resolution_g: number | null;
  has_draft_shield: boolean;
  calibration_date: Date | null;
  calibration_vendor: string | null;
  calibration_tehnician: string | null;
  calibration_id: string | null;
  note: string | null;
};

const BalancesModalForm = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors, isLoading, isSubmitting, isDirty },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: async () => {
      dispatch(overlayActions.open({ path: BACK_URL }));
      const defaultData: FormData = {
        name: '',
        serial_number: '',
        model_number: '',
        manufacturer: '',
        max_capacity_g: null,
        resolution_g: null,
        has_draft_shield: false,
        calibration_date: null,
        calibration_vendor: null,
        calibration_tehnician: null,
        calibration_id: null,
        note: null,
      };
      if (params.id) {
        try {
          const { data } = await BalanceService.find(params.id);
          return { ...defaultData, ...data };
        } catch (error) {
          toast.error('Balance could not be loaded.');
          navigate(BACK_URL);
          return defaultData;
        }
      } else {
        return defaultData;
      }
    },
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();
  const serialNumber = watch('serial_number');
  const modelNumber = watch('model_number');

  useEffect(() => {
    //if Balance has already been created we don't run this logic
    if (params.id) {
      return;
    }

    updateBalanceInfo();
  }, [serialNumber, modelNumber]);

  const updateBalanceInfo = () => {
    clearErrors(['max_capacity_g', 'resolution_g', 'manufacturer', 'has_draft_shield', 'name']);

    let capacity = getValues('max_capacity_g');
    let manufacturer = getValues('manufacturer');
    let resolution = getValues('resolution_g');
    let has_draft_shield = getValues('has_draft_shield');

    switch (modelNumber) {
      case 'BCA1203I-1S':
        manufacturer = 'Sartorious';
        capacity = 1100;
        resolution = 0.001;
        has_draft_shield = true;
        break;
      case 'SECURA225D-1S':
      case 'BCE224-1S':
        manufacturer = 'Sartorious';
        capacity = 210;
        resolution = 0.0001;
        has_draft_shield = true;
        break;
      case 'BCE2202-1S':
        manufacturer = 'Sartorious';
        capacity = 2100;
        resolution = 0.01;
        has_draft_shield = false;
        break;
    }
    setValue('name', `${modelNumber}_${serialNumber}`);
    setValue('max_capacity_g', capacity);
    setValue('manufacturer', manufacturer);
    setValue('resolution_g', resolution);
    setValue('has_draft_shield', has_draft_shield);
  };

  /**
   * Submit form and create balance
   *
   * @param formData Balance data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      ...formData,
      name: formData.name.trim(),
      serial_number: formData.serial_number.trim(),
      model_number: formData.model_number.trim(),
      manufacturer: formData.manufacturer?.trim(),
      max_capacity_g: Number(formData.max_capacity_g),
      resolution_g: formData.resolution_g === null ? null : Number(formData.resolution_g),
      has_draft_shield: formData.has_draft_shield,
      calibration_date: formData.calibration_date ? new Date(formData.calibration_date) : null,
      calibration_vendor:
        formData.calibration_vendor === '' || formData.calibration_vendor === null
          ? null
          : formData.calibration_vendor?.trim(),
      calibration_tehnician:
        formData.calibration_tehnician === '' || formData.calibration_tehnician === null
          ? null
          : formData.calibration_tehnician?.trim(),
      calibration_id:
        formData.calibration_id === '' || formData.calibration_id === null ? null : formData.calibration_id?.trim(),
      note: formData.note === '' || formData.note === null ? null : formData.note?.trim(),
    };

    if (params.id) {
      try {
        await BalanceService.update(params.id, data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_BALANCES],
        });
        toast.success('The balance was successfully updated!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The equipment could not be updated. Please try again.';
        if (e?.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    } else {
      try {
        await BalanceService.create(data);
        queryClient.invalidateQueries({
          queryKey: [ReactQueryKeys.EQUIPMENT_BALANCES],
        });
        toast.success('The balance was successfully created!');
        navigate(BACK_URL);
      } catch (e: any) {
        let message = 'The equipment could not be created. Please try again.';
        if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
          message = parseErrorMessage(e.response.data);
        }
        toast.error(message);
      }
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading && <Spinner isAbsolute />}

        <div className="row">
          {/* Name */}
          <div className="col-6">
            <label htmlFor="name" className="form-label">
              Name *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="name"
              data-testid="name"
              {...register('name', {
                required: true,
                maxLength: 64,
                minLength: 3,
              })}
            />
            {errors?.name?.type === 'required' && <div className="invalid-feedback pt-1">Balance Name is required</div>}
            {errors?.name?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Balance Name must have maximum 64 characters</div>
            )}
            {errors?.name?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Balance Name must have minimum 3 characters</div>
            )}
          </div>

          {/* Serial Number */}
          <div className="col-6">
            <label htmlFor="serial_number" className="form-label">
              Serial Number *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="serial_number"
              disabled={params.id ? true : false}
              data-testid="serial_number"
              {...register('serial_number', {
                required: true,
                maxLength: 24,
                minLength: 1,
              })}
            />
            {errors?.serial_number?.type === 'required' && (
              <div className="invalid-feedback pt-1">Serial Number is required</div>
            )}
            {errors?.serial_number?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Serial Number must have maximum 24 characters</div>
            )}
            {errors?.serial_number?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Serial Number must have minimum 3 characters</div>
            )}
          </div>
        </div>
        <div className="row pt-4">
          {/* Model Number */}
          <div className="col-6">
            <label htmlFor="model_number" className="form-label">
              Model Number *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="model_number"
              disabled={params.id ? true : false}
              data-testid="model_number"
              {...register('model_number', {
                required: true,
                maxLength: 24,
                minLength: 1,
              })}
            />
            {errors?.model_number?.type === 'required' && (
              <div className="invalid-feedback pt-1">Model Number is required</div>
            )}
            {errors?.model_number?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Model Number must have maximum 24 characters</div>
            )}
            {errors?.model_number?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Model Number must have minimum 1 characters</div>
            )}
          </div>

          {/* Manufacturer */}
          <div className="col-6">
            <label htmlFor="manufacturer" className="form-label">
              Manufacturer *
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="manufacturer"
              data-testid="manufacturer"
              {...register('manufacturer', {
                required: true,
                maxLength: 48,
                minLength: 1,
              })}
            />
            {errors?.manufacturer?.type === 'required' && (
              <div className="invalid-feedback pt-1">Manufacturer is required</div>
            )}
            {errors?.manufacturer?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Manufacturer must have maximum 48 characters</div>
            )}
            {errors?.manufacturer?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Manufacturer must have minimum 1 characters</div>
            )}
          </div>
        </div>
        <div className="row pt-4">
          {/* Max Capacity (g) */}
          <div className="col-6">
            <label htmlFor="density" className="form-label">
              Max Capacity (g) *
            </label>
            <Controller
              control={control}
              name="max_capacity_g"
              rules={{
                required: true,
                min: 1,
                max: 99999,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  className={'form-control form-control-lg rounded'}
                  id="max_capacity_g"
                  data-testid="max_capacity_g"
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={0}
                  ref={ref}
                  alignCenter={false}
                  inputGroup={true}
                  inputGroupText="g"
                />
              )}
            />
            {errors?.max_capacity_g?.type === 'required' && (
              <div className="invalid-feedback pt-1">Max Capacity is required</div>
            )}
            {(errors?.max_capacity_g?.type === 'max' || errors?.max_capacity_g?.type === 'min') && (
              <div className="invalid-feedback pt-1">Max Capacity value should be between 1 and 99999</div>
            )}
          </div>

          {/* Balance Resolution */}
          <div className="col-6">
            <label htmlFor="resolution_g" className="form-label">
              Resolution (g)
            </label>
            <Controller
              control={control}
              name="resolution_g"
              rules={{
                validate: (value) => {
                  if (!value) {
                    return true;
                  }

                  return (
                    validResolutions.includes(parseFloat(String(value))) ||
                    'Resolution value should be enum of: ' + validResolutions.join(', ')
                  );
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DecimalInput
                  className={'form-control form-control-lg rounded'}
                  id="resolution_g"
                  data-testid="resolution_g"
                  value={String(value)}
                  onChange={onChange}
                  onBlur={onBlur}
                  precision={5}
                  ref={ref}
                  alignCenter={false}
                  inputGroup={true}
                  inputGroupText="g"
                />
              )}
            />
            {errors?.resolution_g && (
              <div className="invalid-feedback pt-1">{errors.resolution_g.message || 'Invalid resolution value'}</div>
            )}
          </div>
        </div>
        <div className="row pt-4">
          {/* Has Draft Shield */}
          <div className="col-6">
            <p className="d-flex">Has Draft Shield?</p>
            <Controller
              control={control}
              name="has_draft_shield"
              rules={{}}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  disabled={false}
                  id="has_draft_shield"
                  data-testid="has_draft_shield"
                  title=""
                  checked={value}
                  ref={ref}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={'has_draft_shield'}
                />
              )}
            />
          </div>

          {/* Calibration Date */}
          <div className="col-6">
            <label htmlFor="calibration_date" className="form-label">
              Calibration Date
            </label>
            <Controller
              control={control}
              name="calibration_date"
              rules={{
                required: false,
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DatePicker
                  wrapperClassName="form-control"
                  className="form-control form-control-lg rounded"
                  id="calibration_date"
                  selected={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  isClearable={true}
                  maxDate={new Date()}
                  ref={ref}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              )}
            />
          </div>
        </div>
        <div className="row pt-4">
          {/* Calibration Vendor */}
          <div className="col-6">
            <label htmlFor="calibration_vendor" className="form-label">
              Calibration Vendor
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="calibration_vendor"
              data-testid="calibration_vendor"
              {...register('calibration_vendor', {
                maxLength: 48,
                minLength: 1,
              })}
            />
            {errors?.calibration_vendor?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Calibration Vendor must have maximum 48 characters</div>
            )}
            {errors?.calibration_vendor?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Calibration Vendor must have minimum 1 characters</div>
            )}
          </div>
          {/* Calibration Technician */}
          <div className="col-6">
            <label htmlFor="calibration_tehnician" className="form-label">
              Calibration Technician
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="calibration_tehnician"
              data-testid="calibration_tehnician"
              {...register('calibration_tehnician', {
                maxLength: 48,
                minLength: 1,
              })}
            />
            {errors?.calibration_tehnician?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Calibration Technician must have maximum 48 characters</div>
            )}
            {errors?.calibration_tehnician?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Calibration Technician must have minimum 1 characters</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Calibration ID */}
          <div className="col-6">
            <label htmlFor="calibration_id" className="form-label">
              Calibration ID
            </label>
            <input
              type="text"
              autoFocus
              className="form-control form-control-lg rounded"
              id="calibration_id"
              data-testid="calibration_id"
              {...register('calibration_id', {
                maxLength: 64,
                minLength: 1,
              })}
            />
            {errors?.calibration_id?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Calibration ID must have maximum 64 characters</div>
            )}
            {errors?.calibration_id?.type === 'minLength' && (
              <div className="invalid-feedback pt-1">Calibration ID must have minimum 1 characters</div>
            )}
          </div>
        </div>

        <div className="row pt-4">
          {/* Note */}
          <div className="col-12">
            <label htmlFor="note" className="form-label">
              Note
            </label>
            <textarea
              rows={1}
              id="note"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('note', {
                required: false,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.note?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Note must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={isSubmitting || isLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${isSubmitting || isLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default BalancesModalForm;
