import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { InfusionDevice } from 'interfaces/equipment/infusion-device';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/infusion-devices';

export type InfusionDeviceResponse = PaginationResponse<InfusionDevice>;

const InfusionDeviceService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
    has_tac?: boolean;
  }) => {
    return axiosInstance.get<InfusionDeviceResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<InfusionDevice>) => {
    return axiosInstance.patch<InfusionDevice>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<InfusionDevice>) => {
    return axiosInstance.post<InfusionDevice>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<InfusionDevice>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<InfusionDevice>(`${BASE_URL}/${id}`);
  },
};

export default InfusionDeviceService;
