import { ColumnFilter, ColumnSort } from '@tanstack/react-table';
import axiosInstance from 'config/axios';
import { IVSet } from 'interfaces/equipment/iv-set';
import { PaginationResponse } from 'interfaces/pagination-response';

const BASE_URL = '/v1/equipment/iv-sets';

export type IVSetResponse = PaginationResponse<IVSet>;

const IVSetService = {
  get: (queryParams: {
    pageIndex: number;
    pageSize: number;
    sorting?: ColumnSort[];
    columnFilters: ColumnFilter[];
    has_tac?: boolean;
  }) => {
    return axiosInstance.get<IVSetResponse>(BASE_URL, {
      params: queryParams,
    });
  },

  update: (id: string | number, data: Partial<IVSet>) => {
    return axiosInstance.patch<IVSet>(`${BASE_URL}/${id}`, data);
  },

  create: (data: Partial<IVSet>) => {
    return axiosInstance.post<IVSet>(BASE_URL, data);
  },

  find: (id: string | number) => {
    return axiosInstance.get<IVSet>(`${BASE_URL}/${id}`);
  },

  delete: (id: string | number) => {
    return axiosInstance.delete<IVSet>(`${BASE_URL}/${id}`);
  },
};

export default IVSetService;
