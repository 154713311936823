import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
  ColumnDef,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
  Table as ReactTable,
} from '@tanstack/react-table';
import GroupBox from 'components/UI/GroupBox/GroupBox';
import { SideTabsLinks } from 'components/UI/GroupBox/SideTabsLinks';
import Pagination from 'components/UI/Pagination';
import Table from 'components/UI/Table';
import TableActions from 'components/UI/TableActions';
import { EQUIPMENT_LINKS } from 'constants/equipment-links';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { formatDateLong } from 'helpers/date-helpers';
import { TestAutomationController } from 'interfaces/equipment/test-automation-controller';
import { useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import TACService from 'services/TACService';

const TestAutomationControllers = () => {
  /**
   * Define columns for the table
   */
  const columns = useMemo<ColumnDef<TestAutomationController>[]>(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        header: 'Name',
        cell: ({ row }) => <div data-testid={`name_${row?.original?.id}`}>{`${row.original.name}`}</div>,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'test_group',
        accessorKey: 'test_group',
        header: 'Device Group',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>{!row?.original?.test_group?.name ? 'Awaiting Adoption' : row?.original?.test_group.name}</div>
        ),
      },
      {
        id: 'infusion_device',
        accessorKey: 'infusion_device',
        header: 'Paired Infusion Device',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>
            {!row?.original?.infusion_device?.serial_number ? 'N/A' : row?.original?.infusion_device.serial_number}
          </div>
        ),
      },
      {
        id: 'iv_set',
        accessorKey: 'iv_set',
        header: 'IV Set',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{!row?.original?.iv_set?.name ? 'N/A' : row?.original?.iv_set.name}</div>,
      },

      {
        id: 'balance',
        accessorKey: 'balance',
        header: 'Balance',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{!row?.original?.balance?.name ? 'N/A' : row?.original?.balance.name}</div>,
      },

      {
        id: 'fluid_type',
        accessorKey: 'fluid_type',
        header: 'Fluid Type',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{!row?.original?.fluid_type?.name ? 'N/A' : row?.original?.fluid_type.name}</div>,
      },
      {
        id: 'fluid_source',
        accessorKey: 'fluid_source',
        header: 'Fluid Source (bag)',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{!row?.original?.fluid_source?.name ? 'N/A' : row?.original?.fluid_source.name}</div>,
      },
      {
        id: 'needle_type',
        accessorKey: 'needle_type',
        header: 'Needle Type',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => <div>{!row?.original?.needle_type?.name ? 'N/A' : row?.original?.needle_type.name}</div>,
      },
      {
        id: 'container_type',
        accessorKey: 'container_type',
        header: 'Container Type',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div>{!row?.original?.container_type?.name ? 'N/A' : row?.original?.container_type.name}</div>
        ),
      },
      {
        id: 'description',
        accessorKey: 'description',
        header: 'Note',
        cell: ({ row }) => (
          <div data-testid={`description_${row?.original?.id}`}>{`${row?.original?.description ?? ''}`}</div>
        ),
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        id: 'updated_by_user',
        accessorKey: 'updated_by_user',
        header: 'Updated by',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => row?.original?.updated_by_user?.name,
      },
      {
        id: 'updated_at',
        accessorKey: 'updated_at',
        header: 'Update date',
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ row }) => (
          <div className="table-actions-wrapper">
            <div data-testid={`updated_at_${row?.original?.id}`}>{formatDateLong(row?.original?.updated_at)}</div>
            <TableActions editLink={`/configure-equipment/test-automation-controllers/${row.original.id}`} />
          </div>
        ),
      },
    ],
    []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const dataQuery = useQuery({
    queryKey: [ReactQueryKeys.EQUIPMENT_TAC, { pageIndex, pageSize, sorting, columnFilters }],
    queryFn: () =>
      TACService.get({
        pageIndex,
        pageSize,
        sorting,
        columnFilters,
      }),
    placeholderData: keepPreviousData,
  });

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  /**
   * Define react table
   */
  const table: ReactTable<TestAutomationController> = useReactTable({
    data: dataQuery?.data?.data.records ?? [],
    columns,
    pageCount: dataQuery?.data?.data.totalPages ?? -1,
    state: {
      pagination,
      sorting,
      columnFilters,
    },
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
    manualFiltering: true,
    enableMultiSort: false,
  });

  return (
    <>
      <div>
        <GroupBox
          title="CONFIG: Test Automation Controllers"
          summary={[
            {
              title: 'TEST AUTOMATION CONTROLLERS',
              value: dataQuery.data?.data.total ?? 0,
              classes: '',
            },
          ]}
        >
          <div className="d-flex flex-row">
            <SideTabsLinks links={EQUIPMENT_LINKS} />
            <div className="flex-grow-1 side-tabs__content">
              <Table classes="users-table" isFetching={dataQuery.isFetching} table={table} />
              <Pagination table={table} />
            </div>
          </div>
        </GroupBox>
      </div>
      <Outlet />
    </>
  );
};

export default TestAutomationControllers;
