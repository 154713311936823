import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryKeys } from 'constants/react-query-keys';
import { useDispatch } from 'react-redux';
import { overlayActions } from 'store/slices/overlay';
import { parseErrorMessage } from 'helpers/parse-error-message';
import AccessTokenService from 'services/AccessTokenService';
import { useEffect } from 'react';
import SwalAlert from 'components/UI/SwalAlert';
import { VscCopy } from 'react-icons/vsc';

const BACK_URL = '/settings/access-tokens';

type FormData = {
  description: string;
};

const defaultData: FormData = {
  description: '',
};

const AccessTokensModalForm = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormData>({
    mode: 'all',
    defaultValues: { ...defaultData },
  });

  useEffect(() => {
    dispatch(overlayActions.open({ path: BACK_URL }));
  }, []);

  /**
   * Submit form and create user
   *
   * @param formData User data
   */
  const handleSubmitData = async (formData: FormData) => {
    const data = {
      description: formData.description.trim(),
    };

    try {
      const { data: accessToken } = await AccessTokenService.create(data);
      queryClient.invalidateQueries({
        queryKey: [ReactQueryKeys.SETTINGS_ACCESS_TOKENS],
      });
      navigate(BACK_URL);
      SwalAlert.fire({
        title: 'New Access Token',
        html: `<div>
            <div class="alert alert-info" role="alert">
                <p><strong>Info:</strong> This API key is displayed only once, now. Therefore, copy and keep this key somewhere safe but accessible. If you lose this key, you can delete it and generate a new one.</p>
            </div>
            <div>
                <h4>Access Token</h4>
                <div class="alert alert-secondary d-flex justify-content-between align-items-center">
                    <div>${accessToken.api_key} </div>
                    <button class="btn-rounded btn-rounded-sm" id="copy-button" style="background: #fff; box-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m6 19v2c0 .621.52 1 1 1h2v-1.5h-1.5v-1.5zm7.5 3h-3.5v-1.5h3.5zm4.5 0h-3.5v-1.5h3.5zm4-3h-1.5v1.5h-1.5v1.5h2c.478 0 1-.379 1-1zm-1.5-1v-3.363h1.5v3.363zm0-4.363v-3.637h1.5v3.637zm-13-3.637v3.637h-1.5v-3.637zm11.5-4v1.5h1.5v1.5h1.5v-2c0-.478-.379-1-1-1zm-10 0h-2c-.62 0-1 .519-1 1v2h1.5v-1.5h1.5zm4.5 1.5h-3.5v-1.5h3.5zm3-1.5v-2.5h-13v13h2.5v-1.863h1.5v3.363h-4.5c-.48 0-1-.379-1-1v-14c0-.481.38-1 1-1h14c.621 0 1 .522 1 1v4.5h-3.5v-1.5z" fill-rule="nonzero"/></svg>
                    </button>
                </div>
            </div>
        </div>`,
        didOpen: () => {
          const handleCopy = () => {
            navigator.clipboard.writeText(accessToken.api_key).then(() => {
              toast.success('Copied to clipboard');
            });
          };
          const copyButton = document.getElementById('copy-button');
          if (copyButton) {
            copyButton.addEventListener('click', handleCopy);
          }
        },
        confirmButtonColor: '#0895DF',
        showCloseButton: false,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText: 'Done',
      });
    } catch (e: any) {
      let message = 'The access token could not be created. Please try again.';
      if (e.response?.data?.statusCode === 400 || e?.response?.data?.statusCode === 502) {
        message = parseErrorMessage(e.response.data);
      }
      toast.error(message);
    }
  };

  // onKeyUp handler function
  const keyUpHandler = (event: any) => {
    if (event.code === 'Escape') {
      navigate(BACK_URL);
    }
  };

  return (
    <form
      id="formid"
      autoComplete="off"
      data-testid="form"
      onKeyUp={keyUpHandler}
      onSubmit={handleSubmit((data) => handleSubmitData(data))}
    >
      <div className={'modal-sidebar-body pb-4 ' + (isLoading || isSubmitting ? 'opacity-50' : '')}>
        {isLoading ? <Spinner isAbsolute /> : null}

        <div className="row pt-4">
          {/* Description */}
          <div className="col-12">
            <label htmlFor="description" className="form-label">
              Description *
            </label>
            <textarea
              rows={1}
              id="description"
              className="form-control form-control-lg rounded"
              data-testid="description"
              {...register('description', {
                required: true,
                maxLength: 128,
              })}
            ></textarea>
            {errors?.description?.type === 'required' && (
              <div className="invalid-feedback pt-1">Description is required</div>
            )}
            {errors?.description?.type === 'maxLength' && (
              <div className="invalid-feedback pt-1">Description must have maximum 128 characters</div>
            )}
          </div>
        </div>
      </div>

      <div className="modal-sidebar-footer">
        <Button
          loading={isSubmitting}
          defaultLabel="Save"
          loadingLabel={params.id ? 'Updating' : 'Saving'}
          type="submit"
          disabled={isSubmitting || isLoading}
        ></Button>

        <Link
          to={BACK_URL}
          className={`btn btn-lg rounded btn-secondary ${isSubmitting || isLoading ? 'disabled' : ''}`}
        >
          Cancel
        </Link>
      </div>
    </form>
  );
};

export default AccessTokensModalForm;
