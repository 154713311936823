import { TestProtocol } from 'interfaces/tests-scheduler/test-protocol';
import { StagesForm } from 'pages/Lab/TestScheduler/TestProtocols/TestProtocolForm';

export const parseProtocolParameters = (protocolParams: StagesForm[] | null) => {
  const removeNullFromProtocolFormData: TestProtocol['parameters'] = [];
  if (!protocolParams) return removeNullFromProtocolFormData;
  for (const stage of protocolParams) {
    const copied: TestProtocol['parameters'][0] = { ...stage, steps: [] };

    for (const step of stage.steps) {
      switch (step.type) {
        case null:
          continue;

        case 'Program Delivery':
          copied.steps.push({
            ...step,
            delivery_rate: Number(step.delivery_rate),
            delivery_vtbi: Number(step.delivery_vtbi),
          });
          continue;

        case 'Diverter Command':
          if (step.diverter_cmd) {
            copied.steps.push({ ...step, diverter_cmd: step.diverter_cmd });
          }
          continue;

        case 'Wait for Event':
          switch (step.event_type) {
            case null:
              continue;

            case 'wait_for_time':
              copied.steps.push({ ...step, seconds_to_wait: Number(step.seconds_to_wait) });
              continue;

            case 'wait_for_volume':
              copied.steps.push({ ...step, wait_for_ml: Number(step.wait_for_ml) });
              continue;

            case 'wait_for_alarm_state':
              copied.steps.push(step);
              continue;

            case 'wait_for_delivery_state':
              if (step.device_is_delivering) {
                copied.steps.push({
                  ...step,
                  device_is_delivering: step.device_is_delivering,
                });
              }
              continue;

            default: {
              const _unreachable: never = step;
              throw 'Unhandled event type';
            }
          }

        case 'Stop Delivery':
          copied.steps.push(step);
          continue;

        default: {
          const _unreachable: never = step;
          throw 'Unhandled step type';
        }
      }
    }

    removeNullFromProtocolFormData.push(copied);
  }

  return removeNullFromProtocolFormData;
};
